<template>	
	        	<UiSkeleton height="132px" v-if="loading && !accounts.length"></UiSkeleton>
	<UiCol gap="1" v-else-if="accounts.length">
		<router-link class="account-list-item" :to="`/trading/accounts/${account.id}`" v-for="account in accounts" :key="account.id">
			<UiCard full clickable>
			<!-- Account Name -->
			<UiHeadline size="m">{{ account.evaluationName }}</UiHeadline>
			<div style="margin-bottom: 16px;display: flex;justify-content: space-between;">
		        <div style="font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;">
		          {{ account.name }}
		        </div>
		        <div style="display:flex;gap: 8px;">
		        	<!-- <template v-if="accountsSyncStatus.find(s => s.accountId === account.id && s.status == 'failed')">
		        		<UiBadge type="danger">{{ $t('trading_accounts_list.broker_sync') }}</UiBadge>	
		        	</template> -->
		        	<TradingAccountStatus :account="account"/>
		        </div>
	        </div>

              <div style="font-size: 18px;">
                ${{ account.balance }}
              </div>

            <UiProgressbar v-if="account.type === 'sim_live'" :progress="Number(account.progress)"> ({{ account.profit }}/{{account.profitTarget}})</UiProgressbar>
            <img src="/img/medal-1.png" style="width: 24px;position: absolute;top: 0;right: 20px;" v-if="account.type === 'sim_live'">
  				</UiCard>
	      </router-link>
	</UiCol>
	<UiGridTile v-else>
		{{ $t('trading_accounts_list.no_accounts') }}
	</UiGridTile>
	    
	<UiSyncSpinner :syncing="syncing" :status="syncingStatus" style="margin-top: 24px;" v-if="accounts.length && false"></UiSyncSpinner>
</template>

<script setup>
	import { ref, computed, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
	import TabView from 'primevue/tabview';
	import TabPanel from 'primevue/tabpanel';
	import TradingAccountStatus from "@/components/TradingAccountStatus"

	const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()
	const syncing = ref(false)
	const syncingStatus = ref(null)
	const accountsSyncStatus = ref([])
	const loading = ref(false)

	const accounts = computed(() => store.state.dashboard.accounts.filter(a => a.status === 'active'))
	const archivedAccounts = computed(() => store.state.dashboard.accounts.filter(a => a.status === 'inactive'))

	const fetchAccounts = async () => {
		return new Promise(resolve => {
			syncingStatus.value = t('trading_accounts_list.status.fetching_accounts')
			store.dispatch("dashboard/getTradingAccounts").then((res) => {
	      resolve()
	    })
		})
	}

	fetchAccounts().then(() => {
  	loading.value = false
  })

	loading.value = true
	syncing.value = true
	syncingStatus.value = t('trading_accounts_list.status.syncing_accounts')
	store.dispatch("dashboard/syncTradingAccounts").then((res) => {
		accountsSyncStatus.value = res.data.syncStatus
    fetchAccounts().then(() => {
    	syncingStatus.value = t('trading_accounts_list.status.accounts_up_to_date')
    	syncing.value = false
    })
  }).catch(() => {
  	syncingStatus.value = t('trading_accounts_list.status.update_failed')
    syncing.value = false
  })
</script>

<style>
	.account-list-item {
		color: var(--account-list-item_color);
	}
	.account-list-item:hover {

	}
	.p-tabview .p-tabview-panels {
		padding: 1.25rem 0;
	}
</style>