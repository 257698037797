<template>
	<div>
		<!-- Back:Mobile -->
		<div class="mobile">
			<UiBack route="/" :text="$t('view.trading_account.back_button')" fixed></UiBack>
		</div>
		<UiRow class="mb-2" justify="between" align="center">
			<div class="desktop mb-4">
				<UiBack route="/" :text="$t('view.trading_account.back_button')"></UiBack>
			</div>
			<UiRow gap="1" v-if="account && account.type === 'evaluation' && !account.canceled">
				<template v-if="$store.state.dashboard.balance && $store.state.dashboard.balance.balance > 0">
					<UiButton v-if="!account.passed" @click="onResetWithCredit" :loading="loadingReset">
						Use 1 credit to reset <img src="/img/icons/dollar.png" style="height: 16px;">
					</UiButton>
				</template>
				<UiButton v-if="!account.passed" @click="onReset" :loading="loadingReset">
					Reset Account
				</UiButton>
			</UiRow>
		</UiRow>

		<!-- Reset Error -->
		<ui-alert class="mb-8" color="warning" size="medium" v-if="resetErrors && resetErrors.reset">
      <template #title>
        <span class="title">Daily reset limit reached</span>
      </template>
      <template #text>
        <span class="text">{{ resetErrors.reset }}</span>
      </template>
    </ui-alert>

		<!-- Reset Success -->
		<ui-alert class="mb-8" color="success" size="medium" v-if="$route.query.status === 'success'">
      <template #title>
        <span class="title">Success</span>
      </template>
      <template #text>
        <span class="text">Your account has been successfully reset.</span>
      </template>
    </ui-alert>

			<UiRow class="m-block" gap="8" v-if="account">
				<div class="flex-none">
					<UiCard>
						<div style="display: flex;justify-content: space-between;align-items: center;" v-if="account">
					    	<div>
						    	<!-- Headline -->
						    	<div style="display: flex;gap: 4px;align-items: center;margin-bottom: 16px;">
						    		<UiHeadline size="m">{{ account.name }}</UiHeadline>
						    		<div>{{ account.evaluationName }}</div>
								    <div style="font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;">
								      
								    </div>
						    		<TradingAccountStatus :account="account"/>
							  	</div>

							    <UiRow gap="16">
							    	<!-- Balance -->
								    <div>
									    <!-- Subtitle -->
									    <UiHeadline size="s">Balance</UiHeadline>

									    <!-- Balance -->
									    <div class="account-balance">
									      ${{ account.balance }}
									    </div>
								  	</div>

								  	<!-- Trading Days -->
								  	<div>
									    <!-- Subtitle -->
									    <UiHeadline size="s">{{ $t('view.trading_account.trading_days_count.title') }}</UiHeadline>
									    <!-- Trading Days -->
									    <div class="account-trading-days">
									      {{ tradingDays.length }}<small style="color: #C2C9CB;" v-if="account.type !== 'live'"><!--/10--></small>
									    </div>
								  	</div>
							  	</UiRow>
						    </div>
					    </div>

				    	<!-- Buttons -->
					    <div style="display: flex;align-items: center;gap: 4px;margin-top: 16px;" v-if="account">
					    	<template v-if="account.type === 'evaluation' && account.passed && !account.canceled">
						    	<!-- Step 1 Passed -->
						    	<template v-if="account.hasSecondStep && account.currentStep == 1">
						    		<UiButton @click="startSecondStep" :loading="loadingSecondStep">
						    			Start Second Step
						    		</UiButton>
						    	</template>

						    	<!-- Evaluation Passed -->
						    	<template v-else>
							    	<UiButton v-if="!account.fundingStatus.interview" :to="`/trading/accounts/${account.id}/onboarding`">
							    		Start Funded Onboarding
							    	</UiButton>
							    	<UiButton v-if="account.fundingStatus.interview && !account.fundingStatus.agreement" :to="`/trading/accounts/${account.id}/onboarding`">
							    		Sign Trader Contract
							    	</UiButton>
							    	<UiButton v-if="account.fundingStatus.interview && account.fundingStatus.agreement && !account.fundingStatus.fee" :href="account.fundingStatus.activationLink">
							    		Pay funded activation fee
							    	</UiButton>
						    	</template>
					    	</template>

					    	<template v-if="account.type === 'sim_live'">
					    		<UiButton v-if="account.passed && !account.fundingStatus.interview" :to="`/trading/accounts/${account.id}/onboarding/trading-desk`">
						    		Start Trading Desk Onboarding
						    	</UiButton>
					    	</template>
					    </div>
					</UiCard>
				</div>
				
				<!-- Evaluation Objections -->
				<div>
					<UiCard v-if="past7tradingDays.length && account.evalParams">
						<UiHeadline class="mb-2" size="s">Challenge Status</UiHeadline>
							<!-- Trading Days -->
							<UiRow gap="2">
								<UiLabel>Trading Days:</UiLabel>
								<strong :class="{'text-success':tradingDays.length >= account.evalParams.minDays}">{{ tradingDays.length }} / {{ account.evalParams.minDays }}</strong>
								<Icon icon="check" size="16" v-if="tradingDays.length >= account.evalParams.minDays" color="#4d8e47"/>
							</UiRow>
							<!-- Profit Target -->
							<UiRow gap="2">
								<UiLabel>Profit Target:</UiLabel>
								<strong :class="{'text-success':account.profit >= account.evalParams.profitTarget}">{{ account._profit }} / {{ account.profitTarget }}</strong>
								<Icon icon="check" size="16" v-if="account.profit >= account.evalParams.profitTarget" color="#4d8e47"/>
							</UiRow>
							<!-- Consistent Days -->
							<UiRow gap="2" v-if="account.evalParams.minStableDays">
								<UiLabel>Consistent Days:</UiLabel>
								<strong :class="{'text-success':account.tradingDaysAbove200 >= account.evalParams.minStableDays}">{{ account.tradingDaysAbove200 }} / {{ account.evalParams.minStableDays }}</strong>
								<Icon icon="check" size="16" v-if="account.tradingDaysAbove200 >= account.evalParams.minStableDays" color="#4d8e47"/>
							</UiRow>
						</UiCard>
					</div>
				</UiRow>
			<UiSkeleton style="width: 100%;border-radius: 16px;" height="156px" v-else></UiSkeleton>

			<!-- Past Trading Days -->
			<UiCard class="mt-8 desktop">
				<UiHeadline class="mb-2" size="s">Past 7 Trading Days</UiHeadline>
				<PastTradingDays :items="past7tradingDays" v-if="past7tradingDays.length"></PastTradingDays>
				<UiGridTile v-else>
					{{ $t('trading_days.no_trading_days') }}
				</UiGridTile>
			</UiCard>

			<!-- Chart -->
			<div class="mt-8">
				<UiSkeleton style="width: 100%;border-radius: 16px;" height="371px" v-if="fetchingChart"></UiSkeleton>
				<UiCard style="width: 100%;border-radius: 16px;" v-else-if="chart">
					<UiHeadline size="m" class="mb-4">
						{{ $t('view.trading_account.performance_chart.title') }}
					</UiHeadline>
				  <Chart :data-values="chart.data.datasets" :labels="chart.data.labels" :options="chart.options" height="258px"></Chart>
				</UiCard>
			</div>

			<!-- Parameters: Desktop -->
			<UiCard class="desktop mt-8">
				<UiHeadline size="m" class="mb-4">
					Parameters
				</UiHeadline>
				<UiRow gap="2" v-if="account">
					<div full grow style="width:100%;max-width: 300px;">
						<UiCol gap="1">
							<UiGridTile full v-for="stat in account.statsEval">
								<UiRow justify="between">
									<label>{{ $t(stat.title) }}</label>
									<div>
										{{ $t(stat.value) }}
									</div>
								</UiRow>
							</UiGridTile>
						</UiCol>
					</div>
					<div full grow style="width:100%;max-width: 300px;">
						<UiCol gap="1">
							<UiGridTile v-for="stat in riskParameters">
								<UiRow justify="between">
									<label>{{ $t(stat.title) }}</label>{{ $t(stat._value) }}
								</UiRow>
								<small v-html="stat.info"></small>
							</UiGridTile>
						</UiCol>
					</div>
				</UiRow>
			</UiCard>

			<!-- Parameters:Mobile -->
			<div class="mobile">
				<UiCol class="mt-4" gap="2" v-if="account">
					<UiGridTile full grow>
						<div style="margin-bottom: 8px;">Account Levels</div>
						<UiCol gap="1">
							<UiGridTile color="white" v-for="stat in account.statsEval">
								<UiRow justify="between">
									{{ $t(stat.title) }} {{ $t(stat.value) }}
								</UiRow>
								<small>{{ stat.info }}</small>
							</UiGridTile>
						</UiCol>
					</UiGridTile>
					<UiGridTile full grow>
						<div style="margin-bottom: 8px;">Risk Parameters</div>
						<UiCol gap="1">
							<UiGridTile color="white" v-for="stat in riskParameters">
								<UiRow justify="between">
									{{ $t(stat.title) }} {{ $t(stat._value) }}
								</UiRow>
								<small>{{ stat.info }}</small>
							</UiGridTile>
						</UiCol>
					</UiGridTile>
				</UiCol>
			</div>

			<!-- Performance -->
			<div class="mt-8">
				<UiCard style="width: 100%;max-width: 400px;border-radius: 16px;" v-if="performance">
					<UiHeadline size="m" class="mb-4">
						Statistics
					</UiHeadline>
					<UiCol>
					  <UiGridTile>
						<UiRow justify="between">
							<label>Win Rate %</label> <div>{{ performance.winrate }}%</div>
						</UiRow>
					</UiGridTile>
					<UiGridTile color="white">
						<UiRow justify="between">
							<label>Win-Loss Ratio (W/L Ratio)</label> <div>{{ performance.winLossRatio }}</div>
						</UiRow>
					</UiGridTile>
					<UiGridTile>
						<UiRow justify="between">
							<label>Trade Expectancy</label> <div>{{ performance.tradeExpectancy }}</div>
						</UiRow>
					</UiGridTile>
					<UiGridTile color="white" v-if="false">
						<UiRow justify="between">
							<label>Average Winning Trade</label> <div>{{ performance.averageProfitPerTrade }}</div>
						</UiRow>
						<UiRow justify="between">
							<label>Average Losing Trade</label> {{ performance.averageLossPerTrade }}
						</UiRow>
					</UiGridTile>
				</UiCol>
				</UiCard>
				<UiSkeleton style="width: 100%;border-radius: 16px;" height="371px" v-else></UiSkeleton>
			</div>

			<UiRow style="margin-top: 24px;" v-if="$store.state.dashboard.version == '0.2.0'">
		    	<router-link :to="`/trading/accounts/${account.id}/risk`">
			      <UiGridTile>
			      	Change Risk Parameters
			      </UiGridTile>
		    	</router-link>
		  	</UiRow>

		    <!-- Trades -->
		    <div style="margin-top: 71px;" v-if="account && account.passed && $store.state.dashboard.version == '0.2.0'">
		    	<UiHeadline size="m" style="margin-bottom: 16px;">Certificate</UiHeadline>

		    	<div style="display: flex; gap: 71px;">
			    	<div style="flex: none;">
				    	<UiLocked :text="'Complete the Onboarding form to unlock your certificate'">
					    	<div style="width: 509px;height: 311px;border-radius: 16px;position: relative;padding: 16px;background: rgb(200,178,160);background: linear-gradient(45deg, rgba(200,178,160,1) 0%, rgba(233,223,188,1) 100%);">
					    		<div style="font-weight: 600;line-height: 20px;letter-spacing: -0.21px;font-size: 16px;color: #28110c;">
					    			Certificate of achievement
					    		</div>
					    		<div style="font-weight: 600;letter-spacing: -0.21px;font-size: 32px;color: #28110c;">
					    			{{ $store.state.user.user.firstname }} {{ $store.state.user.user.lastname }}
					    		</div>
					    		<div style="font-weight: 600;letter-spacing: -0.21px;font-size: 40px;color: #fff;margin-top: 71px;">
					    			Purdia Trader
					    		</div>
					    		<img src="/img/medal-bronze.png" style="height: 256px;position: absolute;top: 0;right: 24px;">
					    	</div>
				    	</UiLocked>
			    	</div>
			    	<div style="display:flex;justify-content: space-between;align-items: center;">
			    		<UiButton to="/trading/accounts/658b5bdfda98e4cc77deafdd/certificate/form">
				    		Get Your Certificate
				    	</UiButton>
			    	</div>
		    	</div>
		    </div>
	</div>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useRoute, useRouter } from "vue-router"
	import { useStore } from "vuex"
	import { Chart } from "@lib/components"
	import TradingDays from "@/components/TradingDays"
	import PastTradingDays from "@/components/PastTradingDays"
	import TradingAccountStatus from "@/components/TradingAccountStatus"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()
	const chart = ref(null)
	const riskParameters = ref(null)
	const loadingReset = ref(false)
	const account = computed(() => store.getters['dashboard/account'])
	const performance = ref(null)
	const resetErrors = ref(null)
	const fetchingChart = ref(false)

	const tradingDays = computed(() => store.state.dashboard.tradingDays)
	const past7tradingDays = computed(() => [...store.state.dashboard.tradingDays].splice(0, 7))

	const fetch = () => {
		// Fetch Chart
		fetchingChart.value = true
		store.dispatch("dashboard/getTradingAccountChart", route.params.accountId).then((res) => {
	      chart.value = res.data
	      fetchingChart.value = false
	    }).catch(() => {
	    	fetchingChart.value = false
	    })

		// Fetch Risk Parameters
	    store.dispatch("dashboard/getTradingAccountRiskParameters", route.params.accountId).then((res) => {
	      riskParameters.value = res.data.riskParameters
	    }).catch(() => {})

	    // Fetch Trading Days
	    store.dispatch("dashboard/getTradingDays", route.params.accountId).then((res) => {
	    }).catch(() => {})

	    // Fetch Performance
	    store.dispatch("dashboard/getTradingAccountPerformance", route.params.accountId).then((res) => {
	    	performance.value = res.data.performance
	    }).catch((err) => {})
	  }

	  fetch()

	  // Sync Trading Days
	  store.dispatch("dashboard/syncTradingAccountTradingDays", route.params.accountId).then((res) => {
	  	fetch()
	  }).catch(() => {})

	 const onSelectDate = (date) => {
		//router.push(`/trading/accounts/${account.value.id}/days/${date}`)
	}

	const onReset = () => {
		if (loadingReset.value) return
		loadingReset.value = true
		store.dispatch("dashboard/resetTradingAccount", {
			accountId: route.params.accountId,
			data: {
				cancelUrl: `${process.env.VUE_APP_URL}/trading/accounts/${account.value.id}`,
				successUrl: `${process.env.VUE_APP_URL}/?reset_status=success`
			}
		}).then((res) => {
			window.location = res.data.redirectUrl
			loadingReset.value = false
	    }).catch((err) => {
	    	resetErrors.value = err.response.data.errors
	    	loadingReset.value = false
	    })
	}

	const onResetWithCredit = () => {
		if (loadingReset.value) return
		loadingReset.value = true
		store.dispatch("dashboard/resetTradingAccount", {
			accountId: route.params.accountId,
			data: {
				useCredits: true,
				cancelUrl: `${process.env.VUE_APP_URL}/trading/accounts/${account.value.id}`,
				successUrl: `${process.env.VUE_APP_URL}/?reset_status=success`
			}
		}).then((res) => {
			window.location = res.data.redirectUrl
			loadingReset.value = false
	    }).catch((err) => {
	    	resetErrors.value = err.response.data.errors
	    	loadingReset.value = false
	    })
	}

	const loadingSecondStep = ref(false)

	const startSecondStep = () => {
		if (loadingSecondStep.value) return
		loadingSecondStep.value = true
		store.dispatch("dashboard/startSecondStep", {
			accountId: route.params.accountId
		}).then((res) => {
			loadingSecondStep.value = false
			router.push('/')
		}).catch(() => {
			loadingSecondStep.value = false
		})
	}
</script>

<style scoped>
	.account-balance {
		font-size: 24px;font-style: normal;font-weight: 700;line-height: normal;
	}
	.darkmode .account-balance {
		color: #fff;
	}

	.account-trading-days {
		font-size: 20px;font-style: normal;font-weight: 700;line-height: normal;
	}
	.darkmode .account-trading-days {
		color: #fff;
	}
</style>