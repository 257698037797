<template>
  <div class="post-form">
    <div class="mb-2 flex space-x-2 items-center font-medium text-sm text-gray-500" v-if="receiver">
      <div>Send post to</div>
      <UiUserAvatar :user="receiver" size="s"></UiUserAvatar>
      <div>@{{ receiver.username }}</div>
    </div>
    <div v-if="user" class="flex space-x-3">
      <div>
        <UiUserAvatar :user="user" size="l"></UiUserAvatar>
      </div>
      <div class="w-full">
        <div class="form-field">
          <textarea
            v-model="postModel.content"
            rows="3"
            @click="showOptions = true"
            :disabled="submitting"
          />
        </div>
        <div class="bg-yellow-100 rounded-lg font-medium text-sm p-4 mt-2" v-if="error">
          {{error}}
        </div>
        <div v-if="postPreviewImages.length" class="mt-2 mb-4">
          <div
            v-for="image in postPreviewImages"
            class="bg-gray-100 rounded-lg w-full h-40 md:w-64 p-4 border border-gray-200 relative"
            :style="`background: #f3f4f6 url(${image.url});background-position: center;background-size:cover;`"
          >
            <UiCloseButton class="absolute top-0 right-0 m-1" @click="removeImages"></UiCloseButton>
          </div>
        </div>
        <UiYoutubeVideo class="mt-2" v-if="youtubeSrcLink" :src="youtubeSrcLink"></UiYoutubeVideo>
        <div class="md:flex justify-between">
          <div class="flex space-x-2 mt-2 items-center" v-if="false"> 
            <div>
              <input
                type="file"
                class="flex hidden"
                @change="handleImageUpload($event)"
                @click="$event.target.value = ''"
                :disabled="submitting"
                accept="image/*"
              />
              <div class="flex space-x-1 items-center">
                <svg
                  style="height: 24px;"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M3 11.6667C3 10.0712 4.2934 8.77779 5.88889 8.77779H7.23169C8.1976 8.77779 9.0996 8.29505 9.63539 7.49137L10.8091 5.73088C11.3448 4.92719 12.2468 4.44446 13.2128 4.44446H18.7872C19.7532 4.44446 20.6552 4.92719 21.1909 5.73088L22.3646 7.49137C22.9004 8.29506 23.8024 8.77779 24.7683 8.77779H26.1111C27.7066 8.77779 29 10.0712 29 11.6667V24.6667C29 26.2622 27.7066 27.5556 26.1111 27.5556H5.88889C4.2934 27.5556 3 26.2622 3 24.6667V11.6667Z"
                    stroke="#111827"
                    stroke-width="2.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.3333 17.4445C20.3333 19.8377 18.3932 21.7778 16 21.7778C13.6068 21.7778 11.6667 19.8377 11.6667 17.4445C11.6667 15.0512 13.6068 13.1111 16 13.1111C18.3932 13.1111 20.3333 15.0512 20.3333 17.4445Z"
                    stroke="#111827"
                    stroke-width="2.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>Image</div>
              </div>
            </div>
            <div
              v-if="false"
              class="form-field flex items-center space-x-1"
              style="flex-flow: row"
            >
              <label class="block text-xs font-medium text-gray-700">Type</label>
              <select
                v-model="postModel.type"
                class="block rounded-full border-gray-300 shadow-sm text-xs h-8 p-0 pl-3 pr-4"
              >
                <option value="post">Post</option>
                <option value="article">Article</option>
              </select>
            </div>
          </div>
          <div>
            <ui-button rounded @click="onSubmit" :loading="submitting" :disabled="submitting">
              Share Post
            </ui-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="p-4 bg-gray-100 rounded-lg text-sm font-medium text-gray-500"
    >
      You need to
      <span
        class="text-brand cursor-pointer"
        @click="store.dispatch('pit/toggleRequireLoginModal', true)"
        >login</span
      >
      to post
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { useStore } from "vuex";
import convert from "@/services/convert"

const postModelDefault = {
  content: "",
  type: "post",
};

const props = defineProps({
  receiver: Object
})

const store = useStore();
const emit = defineEmits("postCreated");
const postModel = ref(postModelDefault);
const postImages = ref(new FormData());
const postPreviewImages = ref([]);
const submitting = ref(false)
const error = ref(null)

const user = computed(() => store.state.user.user);
const showOptions = ref(false);

const handleImageUpload = (e) => {
  const newImages = [];
  newImages.push(...e.target.files);
  postPreviewImages.value = Array.from(e.target.files).map((image) => ({
    url: URL.createObjectURL(image),
  }));
  newImages.forEach((file) => {
    postImages.value.append("files", file);
  });
};

const removeImages = () => {
  postPreviewImages.value = [];
  postImages.value = new FormData();
}

const onSubmit = () => {
  if (!postModel.value.content) {
    error.value = "Please add content (text) to your post before submitting."
    return
  }
  if (submitting.value) return;
  submitting.value = true
  store.dispatch("pit/createPost", {
    ...postModel.value,
    content: (props.receiver ? `@${props.receiver.username} ` : '') + postModel.value.content
  }).then((res) => {
    if (postPreviewImages.value.length) {
      store
        .dispatch("pit/uploadImagesToPost", {
          postId: res.data.post.id,
          files: postImages.value,
        })
        .then((imagesRes) => {
          postModel.value.content = "";
          postModel.value.type = "post";
          removeImages()
          submitting.value = false
          emit("postCreated", res.data.post);
        })
        .catch(() => {});
    } else {
      postModel.value.content = "";
      postModel.value.type = "post";
      removeImages()
      submitting.value = false
      emit("postCreated", res.data.post);
    }
  });
};

watch(
  () => postModel.value.content,
  async () => {
    error.value = ""
  })

const youtubeSrcLink = computed(() => {
  return convert.getYoutubeEmbedLink(postModel.value.content)
})
</script>
