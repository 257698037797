<template>
  <div v-if="_post && !_post.hidden">
    <!-- Head -->
    <PostHead class="mb-1" :post="_post"></PostHead>

    <div class="post-body">
      <!-- Content -->
      <div class="max-w-lg mt-2 mb-4" v-if="_post.images.length">
        <img :src="_post.images[0].url"></img>
      </div>
      <UiYoutubeVideo class="mt-2 mb-4" v-if="youtubeSrcLink" :src="youtubeSrcLink"></UiYoutubeVideo>
      <div class="post-content">
        <div
          class="px-2 pb-3 overflow-hidden"
          ref="content"
          :style="`line-height: 23px; overflow-wrap: anywhere;max-height: ${showMore ? '100%' : '400px'};`"
          v-html="formatContent(_post.content)"
        >
        </div>
        <div class="h-16 w-full bottom-0 left-0 absolute flex items-center justify-center cursor-pointer" style="background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 54.69%);" v-if="readMore && !showMore" @click="showMore = true">
          <span class="text-brand font-medium">Read more</span>
        </div>
      </div>

      <!-- Parent -->
      <div class="bg-gray-50 rounded-2xl p-3 border" v-if="_post.parent">
        <Post :post="_post.parent" hideMenu="true"></Post>
      </div>

      <!-- Menu -->
      <div class="px-2" v-if="!hideMenu">
        <!-- Stats -->
        <Skeleton v-if="fetching" class="rounded-xl" width="100%" height="37px" />

        <div
          v-if="
            _post.likes.length ||
            _post.viewsCount ||
            _post.commentsCount
          "
          class="post-stats"
        >
          <div
            v-if="_post.likes.length"
            class="post-stats-likes"
            title="Likes"
            @click="showLikes=true"
          >
            <div class="flex -space-x-1">
              <template v-for="like in [..._post.likes].splice(0, 3)">
                <UiAvatar class="user-item-avatar" :imgUrl="like.user.profileImageUrl" size="20px"></UiAvatar>
              </template>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="#6b7280"
              style="height: 20px;"
            >
              <path
                d="M3.35663 16.8125C3.35663 15.4663 4.44793 14.375 5.79413 14.375C7.14032 14.375 8.23163 15.4663 8.23163 16.8125V26.5625C8.23163 27.9087 7.14032 29 5.79413 29C4.44793 29 3.35663 27.9087 3.35663 26.5625V16.8125Z"
                fill="#6b7280"
              />
              <path
                d="M9.85663 16.5417V25.3664C9.85663 26.5974 10.5521 27.7228 11.6532 28.2733L11.7342 28.3138C12.6367 28.7651 13.632 29 14.6411 29H23.4423C24.9915 29 26.3253 27.9065 26.6292 26.3874L28.5792 16.6374C28.9814 14.6263 27.4432 12.75 25.3923 12.75H19.6066V6.25C19.6066 4.45507 18.1516 3 16.3566 3C15.4592 3 14.7316 3.72754 14.7316 4.625V5.70833C14.7316 7.11474 14.2755 8.48321 13.4316 9.60833L11.1566 12.6417C10.3128 13.7668 9.85663 15.1353 9.85663 16.5417Z"
                fill="#6b7280"
              />
            </svg>
            <div>{{ _post.likes.length }}</div>
          </div>
          <Modal title="Likes" v-if="showLikes" @close="showLikes = false">
            <div class="p-6">
              <div class="flex space-x-2 mb-2" v-for="like in [..._post.likes]">
                <UiUserAvatar :user="like.user"></UiUserAvatar>
                <div class="flex items-center">
                  <div class="font-medium text-gray-800">{{ like.user.firstname }} <span class="text-gray-500">@{{ like.user.username }}</span></div>
                </div>
              </div>
            </div>
          </Modal>
          <div
            v-if="_post.viewsCount"
            class="flex items-center space-x-1"
            title="Views"
          >
            <svg
              class="h-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="#6b7280"
            >
              <path
                d="M3 17.625C3 16.7275 3.72754 16 4.625 16H7.875C8.77246 16 9.5 16.7275 9.5 17.625V25.75C9.5 26.6475 8.77246 27.375 7.875 27.375H4.625C3.72754 27.375 3 26.6475 3 25.75V17.625Z"
                fill="#6b7280"
              />
              <path
                d="M12.75 11.125C12.75 10.2275 13.4775 9.5 14.375 9.5H17.625C18.5225 9.5 19.25 10.2275 19.25 11.125V25.75C19.25 26.6475 18.5225 27.375 17.625 27.375H14.375C13.4775 27.375 12.75 26.6475 12.75 25.75V11.125Z"
                fill="#6b7280"
              />
              <path
                d="M22.5 6.25C22.5 5.35254 23.2275 4.625 24.125 4.625H27.375C28.2725 4.625 29 5.35254 29 6.25V25.75C29 26.6475 28.2725 27.375 27.375 27.375H24.125C23.2275 27.375 22.5 26.6475 22.5 25.75V6.25Z"
                fill="#6b7280"
              />
            </svg>
            <div>{{ _post.viewsCount }}</div>
          </div>
          <div v-if="false" class="flex flex-shrink-0 -space-x-1">
            <img
              v-for="member in members"
              :key="member.handle"
              class="h-5 w-5 max-w-none rounded-full ring-2 ring-white"
              :src="member.profileImageUrl"
              :alt="member.name"
            />
          </div>
          <div class="cursor-pointer hover:underline">
            <span v-if="showComments" @click="hideComments">Hide Comments</span>
            <span v-else-if="commentsCount" @click="onShowComments">{{ commentsCount }} Comments</span>
          </div>
        </div>

        <!-- Comment -->
        <div v-if="showComments" class="mt-4">
          <div>
            <!--
            Top Comment
            <div v-if="comments && comments.length">
              <div
                class="h-2 bg-gray-200 rounded-full mb-1 w-1"
                style="margin-left: 18px"
              ></div>
              <Comment
                v-if="comments[0]"
                class="mb-1"
                :comment="comments[0]"
              ></Comment>
            </div>
            -->
            <div class="relative">
              <div
                class="h-full bg-gray-200 rounded-full mb-1 w-1 bottom-0 absolute"
                style="margin-left: 18px"
              ></div>
              <div
                v-if="comments && showComments || fetchingComments"
                class="flex flex-col space-y-2"
              >
                <div v-for="comment in comments" v-if="comments">
                  <Comment :comment="comment"></Comment>
                </div>
                <div class="w-full flex justify-center mt-2">
                  <div class="lds-ring lds-ring-dark" v-if="fetchingComments"><div></div><div></div><div></div><div></div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 flex justify-end">
            <!--
            If only top comment shown
            <template v-if="!showComments">
              <div
                v-if="commentsCount > 1 && comments"
                class="text-brand font-medium text-sm cursor-pointer"
                @click="onToggleComments"
              >
                Show More ({{ commentsCount }})
              </div>
            </template>
            -->
          </div>
        </div>

        <CommentForm
          v-if="showCommentForm"
          class="border-t border-gray-100 mt-1"
          :post-id="_post.id"
          @submit="onComment"
        ></CommentForm>

        <div
          class="post-action-buttons"
        >
          <template v-if="post.liked">
            <UiButton
              color="link"
              @click="onRemoveLike(_post.id)"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="#205eff"
                  style="height: 24px;"
                >
                  <path
                    d="M3.35663 16.8125C3.35663 15.4663 4.44793 14.375 5.79413 14.375C7.14032 14.375 8.23163 15.4663 8.23163 16.8125V26.5625C8.23163 27.9087 7.14032 29 5.79413 29C4.44793 29 3.35663 27.9087 3.35663 26.5625V16.8125Z"
                    fill="#205eff"
                  />
                  <path
                    d="M9.85663 16.5417V25.3664C9.85663 26.5974 10.5521 27.7228 11.6532 28.2733L11.7342 28.3138C12.6367 28.7651 13.632 29 14.6411 29H23.4423C24.9915 29 26.3253 27.9065 26.6292 26.3874L28.5792 16.6374C28.9814 14.6263 27.4432 12.75 25.3923 12.75H19.6066V6.25C19.6066 4.45507 18.1516 3 16.3566 3C15.4592 3 14.7316 3.72754 14.7316 4.625V5.70833C14.7316 7.11474 14.2755 8.48321 13.4316 9.60833L11.1566 12.6417C10.3128 13.7668 9.85663 15.1353 9.85663 16.5417Z"
                    fill="#205eff"
                  />
                </svg>
              </div>
            </UiButton>
          </template>
          <template v-else>
            <UiButton
              color="link"
              @click="onLike(_post.id)"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="none"
                  style="height: 24px;"
                >
                  <path
                    d="M3.35663 16.8125C3.35663 15.4663 4.44793 14.375 5.79413 14.375C7.14032 14.375 8.23163 15.4663 8.23163 16.8125V26.5625C8.23163 27.9087 7.14032 29 5.79413 29C4.44793 29 3.35663 27.9087 3.35663 26.5625V16.8125Z"
                    fill="#111827"
                  />
                  <path
                    d="M9.85663 16.5417V25.3664C9.85663 26.5974 10.5521 27.7228 11.6532 28.2733L11.7342 28.3138C12.6367 28.7651 13.632 29 14.6411 29H23.4423C24.9915 29 26.3253 27.9065 26.6292 26.3874L28.5792 16.6374C28.9814 14.6263 27.4432 12.75 25.3923 12.75H19.6066V6.25C19.6066 4.45507 18.1516 3 16.3566 3C15.4592 3 14.7316 3.72754 14.7316 4.625V5.70833C14.7316 7.11474 14.2755 8.48321 13.4316 9.60833L11.1566 12.6417C10.3128 13.7668 9.85663 15.1353 9.85663 16.5417Z"
                    fill="rgb(31, 41, 55)"
                  />
                </svg>
              </div>
              <div>Like</div>
            </UiButton>
          </template>
          <UiButton
            color="link"
            @click="onClickComment"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="none"
                style="height: 24px;"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M29 20.0625V7.0625C29 5.26757 27.5449 3.8125 25.75 3.8125H6.25C4.45507 3.8125 3 5.26757 3 7.0625V20.0625C3 21.8574 4.45507 23.3125 6.25 23.3125H11.125L16 28.1875L20.875 23.3125H25.75C27.5449 23.3125 29 21.8574 29 20.0625ZM7.875 10.3125C7.875 9.41504 8.60254 8.6875 9.5 8.6875H22.5C23.3975 8.6875 24.125 9.41504 24.125 10.3125C24.125 11.21 23.3975 11.9375 22.5 11.9375H9.5C8.60254 11.9375 7.875 11.21 7.875 10.3125ZM9.5 15.1875C8.60254 15.1875 7.875 15.915 7.875 16.8125C7.875 17.71 8.60254 18.4375 9.5 18.4375H14.375C15.2725 18.4375 16 17.71 16 16.8125C16 15.915 15.2725 15.1875 14.375 15.1875H9.5Z"
                  fill="rgb(31, 41, 55)"
                />
              </svg>
            </div>
            <div>Comment</div>
          </UiButton>
          <div
            v-if="false"
            class="flex items-center p-2 hover:bg-gray-100 rounded-lg cursor-pointer space-x-2 md:space-x-2 text-sm md:text-base"
          >
            <div>
              <svg
                class="h-5 md:h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M7.875 6.25C7.875 4.45507 9.33007 3 11.125 3H20.875C22.6699 3 24.125 4.45507 24.125 6.25V29L16 24.9375L7.875 29V6.25Z"
                  fill="rgb(31, 41, 55)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import PostHead from "./PostHead";
import Post from "./Post";
import convert from "@/services/convert"
import Skeleton from "primevue/skeleton";
const props = defineProps(["post", "hideMenu"]);
const store = useStore();
const showCommentForm = ref(false);
const showComments = ref(false);
const likeLoading = ref(false);
const removeLikeLoading = ref(false);
const _post = ref(null);
const fetching = ref(false);
const showLikes = ref(false)
const fetchingComments = ref(false)
const comments = ref([])
const commentsCount = ref(0)
const content = ref(null)
const readMore = ref(false)
const showMore = ref(false)

watch(
  () => props.post,
  async () => {
    _post.value = props.post;
    if(props.post.commentsCount > 0) commentsCount.value = props.post.commentsCount
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  if (content.value) {
    if (content.value.clientHeight === 400) {
      readMore.value = true
    }
  }
});

const onLike = (postId) => {
  if (likeLoading.value) return;
  if (!store.state.auth.authenticated) {
    store.dispatch("pit/toggleRequireLoginModal", true);
    return;
  }
  likeLoading.value = true;
  store
    .dispatch("pit/likePost", { postId, user: store.state.user.user })
    .then((res) => {
      likeLoading.value = false;
    });
};

const onRemoveLike = (postId) => {
  if (removeLikeLoading.value) return;
  if (!store.state.auth.authenticated) {
    store.dispatch("pit/toggleRequireLoginModal", true);
    return;
  }
  removeLikeLoading.value = true;
  store
    .dispatch("pit/removeLikePost", {
      postId,
      userId: store.state.user.user.id,
    })
    .then((res) => {
      removeLikeLoading.value = false;
    });
};

const onToggleComments = () => {
  loadComments()
  showComments.value = !showComments.value;
  showCommentForm.value = !showCommentForm.value;
};

const hideComments = () => {
  showComments.value = false
}

const onShowComments = () => {
  loadComments()
  showComments.value = true
}

const onClickComment = () => {
  if (!store.state.auth.authenticated)
    return store.dispatch("pit/toggleRequireLoginModal", true);
  showCommentForm.value = !showCommentForm.value;
};

const onReadMore = () => {
  showMore.value = true
}

watch(
  () => store.state.auth.authenticated,
  async (newId) => {
    if (store.state.auth.authenticated) {
      store.dispatch("pit/toggleRequireLoginModal", false);
    }
  },
  { immediate: true }
);

const formatContent = (message) => {
  const pattern = /@(\w+)/g;
  return message.replace(/\n/g, "<br />").replace(
    pattern,
    '<a class="font-medium" href="https://purdia.com/app/member/$1">@$1</a>'
  );
};

const loadComments = () => {
  fetchingComments.value = true;
  store
  .dispatch("pit/getPostComments", {
    postId: props.post.id,
  })
  .then((res) => {
    comments.value = res.data.comments
    fetchingComments.value = false;
  });
}

const onComment = (data) => {
  comments.value.push(data)
  showComments.value = true
  showCommentForm.value = false
  commentsCount.value = commentsCount.value + 1
}

const youtubeSrcLink = computed(() => {
  return convert.getYoutubeEmbedLink(_post.value.content)
})
</script>

<style>
  .post-action-buttons {
    display: flex;
    justify-content: end;
  }
  .post-stats {
    border-top: 1px solid #f6f7f7;
    display: flex;
    gap: 16px;
    padding-top: 16px;
  }
  .post-stats-likes {
    display: flex;
    align-items: center;
  }
  .post-content {
    font-size: 19px;
    padding-bottom: 16px;
  }
  .post-body {
    padding-top: 16px;
  }
</style>