<template>
	<div @click="onClick">
        <div>
          {{ $t('trading_accounts_balance.available_amount') }}
        </div>
        <div style="display: fle;gap: 9px;justify-content: space-between;" v-if="balance" class="mt-2">
          <div style="font-size: 32px;font-weight: 600;">
            ${{ balance.balance }}
          </div>

          <!-- Info alert for pending payout request -->
          <ui-alert class="mt-4" color="info" size="medium" v-if="hasPendingProcessingApprovedStatus && !successfulPayoutRequest">
            <template #title>
              <span>Information</span>
            </template>
            <template #text>
              <span>You have a pending payout request. Please wait for it to be processed.</span>
            </template>
          </ui-alert>

          <!-- Success alert for eligibility to take a payout -->
          <ui-alert color="success" size="medium" v-if="canRequest && !fetchingPayoutRequests">
            <template #title>
              <span>Notification</span>
            </template>
            <template #text>
              <span>You are able to take a payout. Please proceed with your request.</span>
            </template>
          </ui-alert>

          <div class="mt-2" v-if="balance.canPayout">
            <UiButton to="/balance" style="max-width: 200px;width: 100%;" :disabled="!canRequest">
              {{ $t('trading_accounts_balance.request_payout') }}
            </UiButton>
            <UiButton color="link" to="/balance" v-if="!canRequest">
              Show status
            </UiButton>
          </div>
    
        </div>
        <UiSkeleton height="121px" v-if="loading && !accounts.length"></UiSkeleton>
      </div>
      <UiModal :title="$t('trading_accounts_balance.modal.title')" v-if="showInfo" @close="showInfo = false">
        {{ $t('trading_accounts_balance.modal.content') }}
      </UiModal>

      <!-- Modal -->
      <UiModal title="Request Payout" v-if="$route.name === 'dashboard_balance'" @close="$router.push('/')">

  <UiSkeleton class="mt-8" height="500px" v-if="fetchingPayoutRequests"></UiSkeleton>
  <template v-else>
    <ui-alert class="mt-4" color="info" size="medium" v-if="hasPendingProcessingApprovedStatus && !successfulPayoutRequest">
      <template #title>
        <span>Information</span>
      </template>
      <template #text>
        <span>You have a pending payout request. Please wait for it to be processed.</span>
      </template>
    </ui-alert>
  <template v-if="!successfulPayoutRequest && canRequest">
    <div class="mt-8">
          {{ $t('trading_accounts_balance.available_amount') }}
        </div>
        <div style="display: fle;gap: 9px;justify-content: space-between;" v-if="balance">
          <div style="font-size: 32px;font-weight: 600;">
            ${{ balance.balance }}
          </div>
        </div>

      <!-- Form -->
      <div style="max-width: 512px;margin-top: 42px;">
        <div class="form-row">
          <div class="form-field">
              <label>Withdrawal amount (USD)</label>
              <input
                type="number"
                placeholder="0.00"
                v-model="withdrawalAmount"
              />
              <p v-if="error" style="color: #FF2C46;margin-top: 4px">{{ error }}</p>
          </div>
        </div>
      </div>

      <!-- Stats -->
        <div style="margin-top: 59px;display:grid;grid-template-columns: 1fr 1fr;gap: 19px;">
          <div v-for="item in summary">
            <!-- Label -->
            <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;">
              {{ item.title }}
            </div>

            <!-- Value -->
            <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ item.value }}</div>

            <!-- Info -->
            <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="item.info" v-html="item.info">
            </div>
          </div>
        </div>

    <UiButton class="mb-8" style="margin-top: 71px;" @click="submit" :loading="submitting" :disabled="requestInvalid">Submit Request</UiButton>
  </template>
  <template v-else-if="successfulPayoutRequest">
    <ui-alert class="mt-4" color="success" size="medium">
      <template #title>
        <span>Success</span>
      </template>
      <template #text>
        <span>Your payout request of ${{ withdrawalAmount }} has been sent.</span>
      </template>
    </ui-alert>
  </template>

  <hr v-if="payoutRequests.length">

  <!-- Payout Request -->
  <div v-if="payoutRequests.length" class="mt-8">
    <div>
<UiHeadline class="mb-4" size="m">Payout Requests</UiHeadline>
<table>
  <tr>
    <th>Amount</th>
    <th>Date</th>
    <th>Status</th>
  </tr>
  <tr v-for="payout in payoutRequests">
    <td>${{ payout.data.amount }}</td>
    <td>{{ payout.data.date }}</td>
    <td>
      {{ payout.data.status }}
    </td>
  </tr>
</table>
        </div>
  </div>
  </template>
      </UiModal>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()
  const showInfo = ref(false)
  const submitting = ref(false)
  const successfulPayoutRequest = ref(false)
  const fetchingPayoutRequests = ref(true)

	store.dispatch("dashboard/getTradingBalance")

  const onClick = () => {
    if (!balance.value.canPayout && !showInfo.value) showInfo.value = true
  }

const withdrawalAmount = ref(0)
  const minPayout = ref(100)

  store.dispatch("dashboard/getBalance")
  store.dispatch("dashboard/getPayoutStatus").then(() => {
    fetchingPayoutRequests.value = false
  }).catch(() => {
    fetchingPayoutRequests.value = false
  })

  const balance = computed(() => store.state.dashboard.tradingBalance)
  const payoutRequests = computed(() => store.state.dashboard.payoutRequests)

  const hasPendingProcessingApprovedStatus = computed(() => {
      return payoutRequests.value.some(item => 
          ['pending', 'processing', 'approved'].includes(item.data.status)
      );
  });

  const balanceAfterTransaction = computed(() => {
    if (withdrawalAmount.value < minPayout.value) return 0  
    if (balance.value.balance < withdrawalAmount.value) return 0

    return balance.value.balance - withdrawalAmount.value
  })

  const payoutAmount = computed(() => {
    if (withdrawalAmount.value < minPayout.value) return 0
    if (balance.value.balance < withdrawalAmount.value) return 0

    return withdrawalAmount.value
  })

  const error = computed(() => {
    if (withdrawalAmount.value == 0) return null
    if (withdrawalAmount.value < minPayout.value) return 'The minimum withdrawal amount is $100'
    if (balance.value.balance < withdrawalAmount.value) return 'Your balance is lower than the withdrawal'
  })

  const summary = computed(() => [
    {
      title: 'Payout amount',
      value: `$${(payoutAmount.value * .7).toFixed(2)}`,
      info: '(Your 70% Profit Split)'
    },
    {
      title: 'Balance after transaction',
      value: `$${balanceAfterTransaction.value.toFixed(2)}`,
      info: ''
    },
  ])

  const submit = () => {
    if (error.value || withdrawalAmount.value === 0) {
      return
    }
    submitting.value = true
    store.dispatch("dashboard/requestPayout", { 
      amount: withdrawalAmount.value,
      profitSplit: 70
    }).then(() => {
      submitting.value = false
      successfulPayoutRequest.value = true
      store.dispatch("dashboard/getPayoutStatus")
    }).catch(() => {
      submitting.value = false
      successfulPayoutRequest.value = false
    })
  }

  const canRequest = computed(() => {
    return !hasPendingProcessingApprovedStatus.value && balance.value.canPayout
  })

  const requestInvalid = computed(() => {
    return error.value || withdrawalAmount.value < minPayout.value
  })
</script>