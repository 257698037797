import { createStore } from 'vuex';

import baseModules from '@lib/store';
import adminModule from "./admin"
import dashboardModule from "./dashboard"
import chatModule from "./chat"
import socialModule from "./social"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ...baseModules,
    admin: adminModule,
    dashboard: dashboardModule,
    chat: chatModule,
    pit: socialModule
  },
});
