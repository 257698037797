<template>
  <div v-if="post.isAuthor || (user.value && user.value.isAdmin)" @click="onDeletePost">Delete post</div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps(["post"]);
const user = computed(() => store.state.user.user);

const dropdownItems = computed(() => [
  // {
  //   name: "Bookmark",
  //   key: "bookmark",
  // },
  ...(props.post.isAuthor || (user.value && user.value.isAdmin)
    ? [
        {
          name: props.post.pinned ? "Unpin from Feed" : "Pin to Feed",
          key: props.post.pinned ? "unpin" : "pin",
        },
        {
          name: "Delete",
          key: "delete",
        },
      ]
    : []),
]);

const onDropdown = (key) => {
  if (key === "pin") {
    store.dispatch("pit/pinPost", {
      postId: props.post.id,
    });
  }
  if (key === "unpin") {
    store.dispatch("pit/unpinPost", {
      postId: props.post.id,
    });
  }
  if (key === "delete") {
    store.dispatch("pit/deletePost", {
      postId: props.post.id,
    });
  }
  if (key === "bookmark") {
    store.dispatch("pit/bookmarkPost", {
      postId: props.post.id,
    });
  }
};

const onDeletePost = () => {
  store.dispatch("pit/deletePost", {
    postId: props.post.id,
  });
}
</script>
