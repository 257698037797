import { createRouter, createWebHistory } from 'vue-router';
import { track, init } from "@lib/services/tracking";
import cookie from "@lib/services/cookie";
import store from "../store";
import baseViews from '@lib/views';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import NewsletterView from '../views/NewsletterView.vue';
import ContactView from '../views/ContactView.vue';
import ProjectsView from '../views/ProjectsView.vue';
import ProjectView from '../views/ProjectView.vue';
import BlogView from '../views/BlogView.vue';
import BlogArticleView from '../views/BlogArticleView.vue';
import DonateView from '../views/DonateView.vue';
import LoginView from '../views/LoginView.vue';
import SignUpView from '../views/SignUpView.vue';
import PasswordForgotView from '../views/PasswordForgotView.vue';
import PasswordForgotSuccessView from '../views/PasswordForgotSuccessView.vue';
import PasswordResetView from '../views/PasswordResetView.vue';
import PasswordResetSuccessView from '../views/PasswordResetSuccessView.vue';
import EmailSignUpView from '../views/EmailSignUpView.vue';
import VolunteerView from '../views/VolunteerView.vue';
import VolunteerApplyView from '../views/VolunteerApplyView.vue';
import AppView from '../views/AppView.vue';
import ProductView from '../views/ProductView.vue';
import ProductsView from '../views/ProductsView.vue';
import CourseView from '../views/CourseView.vue';
import DashboardView from '../views/DashboardView.vue';
import ProgramView from '../views/ProgramView.vue';
import RiskDisclosureView from '../views/RiskDisclosureView.vue';
import BookCallView from '../views/BookCallView.vue';
import dashboardViews from '../views/dashboard';
import DeskView from '../views/desk/DeskView.vue';
import DeskIndexView from '../views/desk/DeskIndexView.vue';
import DeskTradingView from '../views/desk/DeskTradingView.vue';
import DeskNewsView from '../views/desk/DeskNewsView.vue';
import TradingDeskIndexView from '../views/trading-desk/TradingDeskIndexView.vue';
import TradingDeskView from '../views/TradingDeskView.vue';
import PerformanceView from '../views/PerformanceView.vue';
import PageView from '../views/PageView.vue';
import CertificateView from '../views/CertificateView.vue';
import SupportChatView from '../views/SupportChatView.vue';
import ErrorView from '../views/ErrorView.vue';
import SuccessView from '../views/SuccessView.vue';
import CreditsConfirmationView from '../views/CreditsConfirmationView.vue';
import PageNotFoundView from '../views/PageNotFoundView.vue';
import CommunityView from '../views/community/CommunityView.vue';
import FeedView from '../views/community/FeedView.vue';
import LeaderboardView from '../views/community/LeaderboardView.vue';
import MembersView from '../views/community/MembersView.vue';

import AdminView from '../views/AdminView.vue';
import AdminAffiliatesView from '../views/admin/AffiliatesView.vue';
import AdminModuleView from '../views/admin/ModuleView.vue';

import CheckoutView from '../views/CheckoutView.vue';

const guard = (to, from, next, redirectRoute = "/login") => {
  if (store.state.auth.authenticated) {
    next();
  } else {
    store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        store.state.auth.authenticated ? next() : next(redirectRoute);
      })
      .catch(() => {
        next(redirectRoute);
      });
  }
};

const auth = (to, from, next, redirectRoute = "/auth") => {
  if (!store.state.auth.authenticated && store.state.auth.accessToken) {
    store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        return store.state.auth.authenticated ? next() : next(redirectRoute);
      })
      .catch(() => {});
  } else {
    next();
  }
};

const loggedIn = (to, from, next) => {
  store
    .dispatch("auth/authenticate", {
      root: true,
    })
    .then(() => (store.state.auth.authenticated ? next("/") : next()))
    .catch(() => next());
};


const routes = [
  ...baseViews,
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    beforeEnter: auth,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    beforeEnter: auth,
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: NewsletterView,
    beforeEnter: auth,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView,
    beforeEnter: auth,
  },
  {
    path: '/projects/:project',
    name: 'project',
    component: ProjectView,
    beforeEnter: auth,
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView,
    beforeEnter: auth,
  },
  {
    path: '/blog/:article',
    name: 'blog_article',
    component: BlogArticleView,
    beforeEnter: auth,
  },
  {
    path: '/donate',
    name: 'donate',
    component: DonateView,
    beforeEnter: auth,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: loggedIn,
  },
  {
    path: '/password/forgot',
    name: 'password_forgot',
    component: PasswordForgotView,
    beforeEnter: loggedIn,
  },
  {
    path: '/password/forgot/success',
    name: 'password_forgot_success',
    component: PasswordForgotSuccessView,
    beforeEnter: loggedIn,
  },
  {
    path: '/password/reset',
    name: 'password_reset',
    component: PasswordResetView,
    beforeEnter: loggedIn,
  },
  {
    path: '/password/reset/success',
    name: 'password_reset_success',
    component: PasswordResetSuccessView,
    beforeEnter: loggedIn,
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: SignUpView,
    beforeEnter: loggedIn,
  },
  {
    path: '/email-sign-up',
    name: 'email_signup',
    component: EmailSignUpView,
    beforeEnter: loggedIn,
  },
  {
    path: '/volunteer',
    name: 'volunteer',
    component: VolunteerView,
    beforeEnter: auth,
  },
  {
    path: '/volunteer/apply',
    name: 'volunteer_apply',
    component: VolunteerApplyView,
    beforeEnter: auth,
  },
  {
    path: '/app',
    name: 'app',
    component: AppView,
    beforeEnter: auth,
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView,
    beforeEnter: auth,
  },
  {
    path: '/courses/:id',
    name: 'course',
    component: CourseView,
    beforeEnter: auth,
  },
  {
    path: '/products/:product',
    name: 'product',
    component: ProductView,
    beforeEnter: auth,
  },
  {
    path: '/',
    name: '',
    component: DashboardView,
    children: dashboardViews,
    beforeEnter: guard,
  },
  {
    path: '/7-weeks',
    name: '7_weeks',
    component: ProgramView,
    beforeEnter: auth,
  },
  {
    path: '/risk-disclosure',
    name: 'risk_disclosure',
    component: RiskDisclosureView,
    beforeEnter: auth,
  },
  {
    path: '/book-call',
    name: 'book_call',
    component: BookCallView,
    beforeEnter: auth,
  },
  {
    path: '/a',
    name: 'admin',
    component: AdminView,
    beforeEnter: auth,
    children: [
      {
        path: 'affiliates',
        name: 'admin_affiliates',
        component: AdminAffiliatesView,
        beforeEnter: auth,
      },
      {
        path: 'm/:moduleKey',
        name: 'admin_module',
        component: AdminModuleView,
        beforeEnter: auth,
      },
    ]
  },
  {
    path: '/signout',
    name: 'signout',
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/signOut')
      window.location = '/login'
    }
  },
  {
    path: '/desk',
    component: DeskView,
    beforeEnter: guard,
    children: [
      {
        path: '',
        name: 'desk',
        component: DeskIndexView
      },
      {
        path: 'trading',
        name: 'trading',
        component: DeskTradingView
      },
      {
        path: 'news',
        name: 'news',
        component: DeskNewsView
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: DeskIndexView
      },
      {
        path: 'market',
        name: 'market',
        component: DeskIndexView
      },
    ]
  },
  {
    path: '/trading-desk',
    name: 'trading_desks',
    component: TradingDeskIndexView,
    beforeEnter: guard,
  },
  {
    path: '/trading-desk/:deskId',
    name: 'trading_desk',
    component: TradingDeskView,
    beforeEnter: guard,
  },
  {
    path: '/performance',
    name: 'performance',
    component: PerformanceView,
    beforeEnter: guard,
  },
  {
    path: '/performance/:accountId',
    name: 'performance',
    component: PerformanceView,
    beforeEnter: guard,
  },
  {
    path: '/page',
    name: 'page',
    component: PageView,
    beforeEnter: guard,
  },
  {
    path: '/certificate/:certificationId',
    name: 'certificate',
    component: CertificateView,
    beforeEnter: guard,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    beforeEnter: auth,
  },
  {
    path: '/support',
    name: 'support',
    component: SupportChatView,
    beforeEnter: auth,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView,
    beforeEnter: auth,
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView,
    beforeEnter: auth,
  },
  {
    path: '/credits/confirmation',
    name: 'credits_confirmation',
    component: CreditsConfirmationView,
    beforeEnter: auth,
  },
  {
    path: '/members',
    name: 'members',
    component: MembersView,
    beforeEnter: auth,
  },
  {
    path: '/community',
    name: 'community',
    component: CommunityView,
    beforeEnter: auth,
    children: [
      {
        path: 'feed',
        name: 'community_feed',
        component: FeedView, 
      },
      {
        path: 'leaderboard',
        name: 'community_leaderboard',
        component: LeaderboardView, 
      },
      {
        path: 'members',
        name: 'community_members',
        component: MembersView, 
      }
    ]
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: PageNotFoundView,
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from) => {
  /* Redirect to Onboarding route if not complete */
  if (store.state.user.user && store.state.user.user.redirectToOnboarding && to.name !== 'dashboard_onboarding' && to.name !== 'signout') {
     router.push('/onboarding?step=0')
  }

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get("lang")) {
    cookie.set('lang', urlParams.get("lang"))
  }

  if (urlParams.get("i")) {
    cookie.set('rff', urlParams.get("i"))
  }

  if (urlParams.get("checkout") === "success") {
    twq('event', 'tw-od6ms-omrdo', {});
    twq('event', 'tw-odisx-oe01o', {});
    gtag('event', 'conversion', {
      'send_to': 'AW-11440055358/_ShYCKfOgcsZEL7Ihc8q',
      'value': 99.0,
      'currency': 'USD',
      'transaction_id': ''
    });

    setTimeout(() => {
      window.location = "/"
    }, 100)
  }

  track("view", null, {
    path: to.path,
  });
});

export default router;
