import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import socket from "@lib/services/socket";
import config from "@lib/store/modules/config";

const PitApi = {
  listGroups() {
    return http.get("/v1/pit/groups");
  },
  listUserGroups() {
    return http.get("/v1/pit/user/groups");
  },
  get(payload) {
    return http.get(`/v1/pit/com/${payload.groupKey}`);
  },
  create(payload) {
    return http.post("/v1/pit", payload);
  },
  createPost(payload) {
    return http.post("/v1/pit/post", payload);
  },
  uploadImagesToPost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/images`, payload.files);
  },
  pinPost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/pin`);
  },
  unpinPost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/unpin`);
  },
  deletePost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/delete`);
  },
  bookmarkPost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/bookmark`);
  },
  likePost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/like`);
  },
  removeLikePost(payload) {
    return http.post(`/v1/pit/post/${payload.postId}/remove-like`);
  },
  listPosts(payload) {
    return http.get("/v1/pit/posts", {
      params: payload ? payload.query : null,
    });
  },
  listPostsByMember(payload) {
    return http.get(`/v1/pit/members/${payload.username}/posts`, {
      params: payload ? payload.query : null,
    });
  },
  getPost(payload) {
    return http.get(`/v1/pit/posts/${payload.postId}`);
  },
  getPinnedPost(payload) {
    return http.get('/v1/pit/posts/pinned');
  },
  getPostComments(payload) {
    return http.get(`/v1/pit/posts/${payload.postId}/comments`);
  },
  listMembers() {
    return http.get("/v1/pit/members");
  },
  getMember(payload) {
    return http.get(`/v1/pit/members/${payload.username}`);
  },
  getMemberFollowers(payload) {
    return http.get(`/v1/pit/members/${payload.username}/followers`);
  },
  getMemberMembers(payload) {
    return http.get(`/v1/pit/members/${payload.username}/members`);
  },
  leaderboard() {
    return http.get(`/v1/pit/leaderboard`);
  },
  follow(payload) {
    return http.post('/v1/pit/follow', payload);
  },
  unfollow(payload) {
    return http.post('/v1/pit/unfollow', payload);
  },
  joinGroup(payload) {
    return http.post('/v1/pit/group/join', payload);
  },
  leaveGroup(payload) {
    return http.post('/v1/pit/group/leave', payload);
  },
};

const socialModule = {
  namespaced: true,
  state: {
    groups: [],
    com: {
      name: 'Purdia',
      key: 'purdia'
    },
    members: [],
    member: null,
    posts: [],
    post: null,
    showRequireLoginModal: false,
    pinnedPost: null,
    userGroups: []
  },
  getters: {
    listGroups: (state) => {
      return state.groups;
    },
    listUserGroups: (state) => {
      return state.userGroups;
    },
    listMembers: (state) => {
      return state.members;
    },
    getMember: (state) => {
      return state.member;
    },
    listPosts: (state, _, rootState) => {
      return state.posts.map((p) => ({
        ...p,
        liked: rootState.user.user
          ? p.likes.find((l) => l.user.id === rootState.user.user.id)
            ? true
            : false
          : false,
      }));
    },
    getPost: (state) => {
      return state.post;
    },
    getPinnedPost: (state) => {
      return state.pinnedPost;
    }
  },
  mutations: {
    setGroups: (state, payload) => {
      state.groups = payload;
    },
    setUserGroups: (state, payload) => {
      state.userGroups = payload;
    },
    set: (state, payload) => {
      state.com = payload;
    },
    setMembers: (state, payload) => {
      state.members = payload;
    },
    setMember: (state, payload) => {
      state.member = payload;
    },
    setPosts: (state, payload) => {
      state.posts = payload;
    },
    addPosts: (state, payload) => {
      state.posts.push(...payload);
    },
    resetPosts: (state) => {
      state.posts = [];
    },
    setPost: (state, payload) => {
      state.post = payload;
    },
    pinPost: (state, payload) => {
      state.pinnedPost = payload
    },
    unpinPost: (state, payload) => {
      state.pinnedPost = null
    },
    deletePost: (state, payload) => {
      const index = state.posts.findIndex((a) => a.id === payload.postId);
      state.posts[index].deleted = true;
    },
    bookmarkPost: (state, payload) => {
      const index = state.posts.findIndex((a) => a.id === payload.postId);
      state.posts[index].bookmarked = true;
    },
    likePost: (state, payload) => {
      const index = state.posts.findIndex((a) => a.id === payload.postId);
      if (!state.posts[index].likes.find((l) => l.user.id === payload.userId)) {
        state.posts[index].liked = true;
        state.posts[index].likes.push({
          user: {
            id: payload.user.id,
            profileImageUrl: payload.user.profileImageUrl,
          },
        });
      }
    },
    removeLikePost: (state, payload) => {
      const index = state.posts.findIndex((a) => a.id === payload.postId);
      const likeIndex = state.posts[index].likes.findIndex(
        (l) => l.user.id === payload.userId
      );

      if (state.posts[index].likes.find((l) => l.user.id === payload.userId)) {
        state.posts[index].likes.splice(likeIndex, 1);
        state.posts[index].liked = false;
      }
    },
    addPost: (state, payload) => {
      state.posts.unshift(payload);
    },
    updatePost: (state, payload) => {
      const index = state.posts.findIndex((p) => p.id === payload.postId);

      state.posts[index] = payload.post;
    },
    addImagesToPost: (state, payload) => {
      const index = state.posts.findIndex((a) => a.id === payload.postId);

      state.posts[index].images = payload.images;
    },
    toggleRequireLoginModal: (state, payload) => {
      state.showRequireLoginModal = payload;
    },
  },
  actions: {
    create: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.create(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    listGroups: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.listGroups(payload)
          .then((response) => {
            commit("setGroups", response.data.groups);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    listUserGroups: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.listUserGroups(payload)
          .then((response) => {
            commit("setUserGroups", response.data.groups);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    get: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.get(payload)
          .then((response) => {
            commit("set", response.data.com);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    listMembers: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.listMembers()
          .then((response) => {
            commit("setMembers", response.data.members);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getMember: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.getMember(payload)
          .then((response) => {
            commit("setMember", response.data.member);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getMemberFollowers: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.getMemberFollowers(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getMemberMembers: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.getMemberMembers(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.createPost(payload)
          .then((response) => {
            if (!response.data.post.parentId) {
              commit("addPost", response.data.post);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    pinPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.pinPost(payload)
          .then((response) => {
            commit("pinPost", response.data.post);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    unpinPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.unpinPost(payload)
          .then((response) => {
            commit("unpinPost");
            commit("updatePost", {
              post: response.data.post,
              postId: response.data.post.id
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    deletePost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.deletePost(payload)
          .then((response) => {
            commit("deletePost", payload);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    bookmarkPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        commit("bookmarkPost", payload);
        PitApi.bookmarkPost(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    uploadImagesToPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.uploadImagesToPost(payload)
          .then((response) => {
            commit("addImagesToPost", {
              postId: payload.postId,
              images: response.data.images,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    likePost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        commit("likePost", payload);
        PitApi.likePost(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    removeLikePost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        commit("removeLikePost", payload);
        PitApi.removeLikePost(payload)
          .then((response) => {
            commit("removeLikePost", payload);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updatePost: ({ commit }, payload) => commit("updatePost", payload),
    getPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.getPost(payload)
          .then((response) => {
            commit("setPost", response.data.post);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getPinnedPost: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.getPinnedPost(payload)
          .then((response) => {
            commit("pinPost", response.data.post);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getPostComments: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.getPostComments(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    listPosts: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.listPosts(payload)
          .then((response) => {
            commit("addPosts", response.data.posts);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    listPostsByMember: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.listPostsByMember(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setPosts: ({ commit }, payload) => commit("setPosts", payload),
    resetPosts: ({ commit }, payload) => commit("resetPosts"),
    toggleRequireLoginModal: ({ commit }, payload) =>
      commit("toggleRequireLoginModal", payload),
    leaderboard: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.leaderboard(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    follow: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.follow(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    unfollow: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.unfollow(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    joinGroup: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.joinGroup(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    leaveGroup: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        PitApi.leaveGroup(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  },
};

export default socialModule;