<template>
  <div :class="['navigation', { 'navigation-fixed': fixed }]">
    <div class="nav-left">
      <slot name="logo"></slot>
    </div>
    <div class="nav-center">
      <slot name="nav-items"></slot>
    </div>
    <div class="nav-right">
      <div class="user-dropdown">
        <UiAvatar :imgUrl="userAvatar" @click="toggleDropdown" />
        <div v-if="dropdownOpen" class="dropdown-menu">
          <slot name="dropdown-menu"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import UiAvatar from '../Avatar/Avatar';

const props = defineProps({
  userAvatar: {
    type: String,
    required: true,
  },
  fixed: {
    type: Boolean,
    default: false,
  },
});

const dropdownOpen = ref(false);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};
</script>

<style scoped>
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
  border-bottom: 1px solid rgb(231, 231, 231);
  height: var(--navigation_height);
}

.nav-left,
.nav-center,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-center {
  flex: 1;
  justify-content: center;
}

.user-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
}

.navigation-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 768px) {
  .navigation {
    flex-direction: column;
  }
  .nav-center {
    order: 2;
  }
  .nav-right {
    order: 3;
  }
  .nav-left {
    order: 1;
  }
}
</style>
