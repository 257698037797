<template>
  <div class="container container-s">
    <div class="widget relative" v-if="pinnedPost">
      <div class="w-full mb-2 flex space-x-2 items-center font-medium text-sm text-brand">
        <div>Post pinned by @{{ pinnedPost.author.username }}</div>
      </div>
      <Post :post="pinnedPost"></Post>
    </div>
    <UiCard class="post">
      <UiHeadline class="mb-4" size="m">Members</UiHeadline>
      <div v-for="post in posts" :key="post.id">
        <UserListItem :user="post.author">
          <template v-slot:foot></template>
          <template v-slot:right></template>
        </UserListItem>
      </div>
      <Skeleton
        v-if="fetching"
        class="rounded-xl"
        width="100%"
        height="219px"
      />
      <Skeleton
        v-if="fetching"
        class="rounded-xl"
        width="100%"
        height="327px"
      />
      <Skeleton
        v-if="fetching"
        class="rounded-xl"
        width="100%"
        height="219px"
      />
      <Skeleton
        v-if="fetching"
        class="rounded-xl"
        width="100%"
        height="219px"
      />
      <Skeleton
        v-if="fetching"
        class="rounded-xl"
        width="100%"
        height="327px"
      />
  </UiCard>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import Skeleton from "primevue/skeleton";
import PostForm from "./components/PostForm";
import Post from "./components/Post";
import ArticleCard from "./components/ArticleCard";
import MemberProfile from "./components/MemberProfile";
import { LayoutUser, UserListItem } from "@lib/components"

const instance = getCurrentInstance();
const t = instance.appContext.config.globalProperties.$t;

const store = useStore();
const fetching = ref(true);
const morePostsAvailable = ref(true);
const newPost = ref(null);

const user = computed(() => store.state.user.user);
const posts = computed(() => store.state.pit.posts);
const group = computed(() => store.state.pit.com);
const pinnedPost = computed(() => store.state.pit.pinnedPost);

const fetch = (query) => {
  fetching.value = true;
  //store.dispatch("pit/getPinnedPost").then().catch()
  store.dispatch("pit/listPosts", { query }).then((res) => {
    if (!res.data.posts.length) morePostsAvailable.value = false;
    // posts.value.push(...res.data.posts)
    fetching.value = false;
  });
};

fetch();

window.onscroll = function (ev) {
  if (fetching.value || !morePostsAvailable.value) return;

  if (
    window.innerHeight + window.pageYOffset >=
    document.body.offsetHeight - 190
  ) {
    fetch({
      lastPostId: posts.value[posts.value.length - 1].id,
      lte: posts.value[posts.value.length - 1].createdAt,
    });
  }
};

  const sidebarItems = ref([
    {
      name: t('view.community.sidebar.feed'),
      route: "/community/feed"
    },
    {
      name: t('view.community.sidebar.leaderboard'),
      route: "/community/leaderboard"
    },
])
</script>

<style>
  .posts .post {
    margin-bottom: 8px;
  }

  .post-form {
    margin-bottom: 16px;
  }
</style>