const extractYouTubeLink = (text) => {
  const regex = /(https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([\w-]+)(?:&(?:[\w?=.-]+))?)|(https?:\/\/youtu\.be\/([\w-]+))/i;
  const match = text.match(regex);

  if (match) {
    const [, fullURL, videoID, shortURL, shortVideoID] = match;

    if (fullURL) {
      return fullURL;
    } else if (shortURL) {
      return `https://www.youtube.com/watch?v=${shortVideoID}`;
    }
  }

  return null;	
}

const extractYouTubeVideoId = (link) => {
  const regex = /(?:youtube\.com\/(?:watch\?.*v=|embed\/|v\/|u\/\w\/|playlist\?.*list=))([^#\&\?]*).*/;
  const match = link.match(regex);
  if (match && match[1]) {
    return match[1];
  }

  return null;
}

const generateYoutubeEmbedLink = (youtubeId) => {
  return youtubeId ? 'https://www.youtube.com/embed/' + youtubeId + '?controls=0' : null
}

const getYoutubeEmbedLink = (text) => {
	if(text) {
		const youtubeLink = extractYouTubeLink(text)
		if (youtubeLink) {
			const youtubeId = extractYouTubeVideoId(youtubeLink)

			return youtubeId ? generateYoutubeEmbedLink(youtubeId) : null
		}
	}

	return null
}

export default {
	extractYouTubeLink,
	extractYouTubeVideoId,
	generateYoutubeEmbedLink,
	getYoutubeEmbedLink
}