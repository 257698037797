<template>
  <div class="sidebar">
    <div class="sidebar-items">
      <div v-for="(item, index) in items" :key="item.key" class="sidebar-item">
        <router-link :to="item.route" class="sidebar-link" @click="handleItemClick">
          <div class="sidebar-content">
            <!--<div class="sidebar-icon-wrapper">
              <UiIcon :icon-name="item.icon" />
              <div v-if="item.notifications && item.notifications > 0" class="notification-circle"></div>
            </div> -->
            <div>{{ item.name }}</div>
            <UiBadge v-if="item.isPro">Pro</UiBadge>
            <div v-if="item.children" class="dropdown-toggle" @click.stop="toggleDropdown(item.key)">
              &#9660;
            </div>
          </div>
        </router-link>
        <ul v-if="item.children && isDropdownOpen(item.key)" class="sidebar-item-children">
          <li v-for="child in item.children" :key="child.key" class="sidebar-child-item">
            <router-link :to="child.route" class="sidebar-link" @click="handleItemClick">
              <div class="sidebar-content">
                <div class="sidebar-icon-wrapper">
                  <UiIcon :icon-name="child.icon" />
                  <div v-if="child.notifications && child.notifications > 0" class="notification-circle"></div>
                </div>
                <div>{{ child.name }}</div>
                <UiBadge v-if="child.isPro">Pro</UiBadge>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import UiIcon from '../Icon/Icon'

const props = defineProps({
  items: Array,
});

const dropdownStates = ref({});

const toggleDropdown = (key) => {
  dropdownStates.value[key] = !dropdownStates.value[key];
};

const isDropdownOpen = (key) => {
  return dropdownStates.value[key];
};

const handleItemClick = () => {
  // Close all dropdowns on item click
  Object.keys(dropdownStates.value).forEach((key) => {
    dropdownStates.value[key] = false;
  });
};
</script>

<style scoped>
.sidebar {
  flex: none;
  padding: 6px;
  background: #f8f9fa;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 6px;
  transition: background 0.3s;
  cursor: pointer;
}

.sidebar-link:hover {
  background: #e9ecef;
}

.sidebar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.sidebar-icon-wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #DFFC8C;
}

.sidebar-icon {
  color: #000;
}

.notification-circle {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}

.sidebar-item-children {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-child-item {
  display: flex;
}

.dropdown-toggle {
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s;
}

.dropdown-toggle:hover {
  background: #e9ecef;
}
</style>
