<template>
  <template v-if="brokerUser">
    <div class="list list-creds">
      <div class="list-item">
        <div>{{ $t('view.trading_platform.credentials.username') }}</div>
        <div>
          <UiCopyToClipboard :text-to-copy="brokerUser.username" @click="onShowCreds">
            <UiRow align="center" gap="2">
              <input :type="secureField" :value="brokerUser.username" style="border: none;outline: none;text-align: right;font-size: 16px;" readonly/>
            </UiRow>
          </UiCopyToClipboard>
        </div>
      </div>
      <div class="list-item">
        <div>{{ $t('view.trading_platform.credentials.password') }}:</div>
        <div>
          <UiCopyToClipboard :text-to-copy="brokerUser.password" @click="onShowCreds">
            <UiRow align="center" gap="2">
              <input :type="secureField" :value="brokerUser.password" style="border: none;outline: none;text-align: right;font-size: 16px;" readonly />
            </UiRow>
          </UiCopyToClipboard>
        </div>
      </div>
    </div>

    <!-- Instruction -->
    <div class="mt-2">
      <label>
        <small>
          Paste the credentials into the respective fields by using "CTRL + V" on Windows or "COMMAND + V" on Mac.
        </small>
      </label>
    </div>

    <!-- Generate new password -->
    <!-- <div style="display:flex; justify-content: end;">
      <a size="s" round>Generate new password</a>
    </div> -->

  </template>
  <UiSkeleton style="width: 100%;border-radius: 16px;" height="94px" v-else-if="loading"></UiSkeleton>
  <template v-else>
    <UiGridTile>
      {{ $t('view.trading_platform.credentials.no_credentials') }}
    </UiGridTile>
    <UiButton class="mt-2" href="https://purdia.com/evaluation">Start Evaluation</UiButton>
  </template>
</template>
<script setup>
import { ref, computed } from "vue"
import { useStore } from "vuex"

const store = useStore()

const loading = ref(true)
const secureField = ref("password")

store.dispatch('dashboard/getBrokerUser').then(() => {
  loading.value = false
}).catch(() => {
  loading.value = false
})

const brokerUser = computed(() => store.state.dashboard.brokerUser)

const onShowCreds = () => {
  secureField.value = "text"
}

</script>


<style scoped>
  .list-creds .list-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }
</style>