<template>
  <LayoutUser>
    <!-- Back:Mobile -->
    <div class="mobile">
      <UiBack route="/" :text="$t('view.trading_account.back_button')" fixed></UiBack>
    </div>
    
    <!-- Tabs -->
    <UiTabsFull class="flex-none" :items="tabItems"></UiTabsFull>
    
    <div class="container" style="display: flex;">
      <!-- Sidebar -->
      <!-- <UiSidebar class="desktop" :items="sidebarItems"></UiSidebar> -->

      <!-- Router -->
      <div style="width: 100%;padding: 12px 0 64px 0;">
        <router-view></router-view>
      </div>
    </div>
  </LayoutUser>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import Skeleton from "primevue/skeleton";
import PostForm from "./components/PostForm";
import Post from "./components/Post";
import ArticleCard from "./components/ArticleCard";
import MemberProfile from "./components/MemberProfile";
import { LayoutUser } from "@lib/components"

const instance = getCurrentInstance();
const t = instance.appContext.config.globalProperties.$t;

const store = useStore();
const fetching = ref(true);
const morePostsAvailable = ref(true);
const newPost = ref(null);

const user = computed(() => store.state.user.user);
const posts = computed(() => store.state.pit.posts);
const group = computed(() => store.state.pit.com);
const pinnedPost = computed(() => store.state.pit.pinnedPost);

const fetch = (query) => {
  fetching.value = true;
  //store.dispatch("pit/getPinnedPost").then().catch()
  store.dispatch("pit/listPosts", { query }).then((res) => {
    if (!res.data.posts.length) morePostsAvailable.value = false;
    // posts.value.push(...res.data.posts)
    fetching.value = false;
  });
};

fetch();

window.onscroll = function (ev) {
  if (fetching.value || !morePostsAvailable.value) return;

  if (
    window.innerHeight + window.pageYOffset >=
    document.body.offsetHeight - 190
  ) {
    fetch({
      lastPostId: posts.value[posts.value.length - 1].id,
      lte: posts.value[posts.value.length - 1].createdAt,
    });
  }
};

  const sidebarItems = ref([
    {
      name: t('view.community.sidebar.feed'),
      route: "/community/feed"
    },
    {
      name: t('view.community.sidebar.leaderboard'),
      route: "/community/leaderboard"
    },
    {
      name: t('view.community.sidebar.members'),
      route: "/community/members"
    },
])
</script>

<style>
  .posts .post {
    margin-bottom: 8px;
  }

  .post-form {
    margin-bottom: 16px;
  }
</style>