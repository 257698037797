import { createI18n } from 'vue-i18n';
import cookie from "@lib/services/cookie";

const messages = {
  en: {
    "navigation.dashboard": "Dashboard",
    "navigation.trading_desk": "Trading Desk",
    "navigation.community": "Community",
    "navigation.trading": "Trading",
    "navigation.education": "Education",
    "navigation.members": "Leaderboard",
    "navigation.calendar": "Calendar",
    "navigation.partner": "Partner",
    "navigation.evaluations": "Evaluations",
    "navigation.faq": "FAQ",
    "navigation.instant_funding": "Instant Funding",
    "navigation.my_account": "My Account",
    "navigation.my_account.profile": "Profile",
    "navigation.my_account.bonus": "Bonus",
    "navigation.my_account.settings": "Settings",
    "navigation.my_account.sign_out": "Sign Out",
    "view.settings.title": "Settings",
    "view.settings.sidebar.account": "Account",
    "view.settings.sidebar.subscriptions": "Subscriptions",
    "view.settings.sidebar.configuration": "Configuration",
    "view.settings.sidebar.affiliate": "Affiliate",
    "view.settings.sidebar.credits": "Credits",
    "view.settings.language": "Language",
    "view.settings.edit_profile": "Edit Profile",
    "view.settings.subscriptions": "Manage Subscriptions",
    "view.settings.profile.title": "Account",
    "view.settings.profile.change_profile_picture": "Change Profile Picture",
    "view.settings.profile.name": "Name",
    "view.settings.profile.username": "Username",
    "view.settings.profile.email": "Email",
    "view.settings.profile.address": "Address",
    "view.settings.profile.update_info": "Can only be updated by an administrator",
    "view.settings.subscriptions.per_month": "month",
    "view.settings.subscriptions.one_time": "One time",
    "view.settings.subscriptions.no_subscriptions": `You have no active subscriptions. To start an evaluation, <a href="https://purdia.com/evaluation">click here.</a>`,
    "view.settings.subscription.title": "Subscription",
    "view.settings.subscription.price": "Price",
    "view.settings.subscription.cancel_subscription": "Cancel Subscription",
    "view.settings.subscription.monthly": "Monthly",
    "view.settings.subscription.status.canceled": "Canceled",
    "view.settings.subscription.cancelation_modal.title": "Are you sure?",
    "view.settings.subscription.cancelation_modal.quit": "No",
    "view.settings.subscription.cancelation_modal.confirm": "Yes Cancel Subscription",
    "view.settings.affiliate.title": "Affiliate Program",
    "view.my_profile.title": "My Profile",
    "view.my_profile.level_at_desk": "Level at Desk",
    "view.my_profile.at_firm_since": "At firm since",
    "view.my_profile.level_junior.description": "As a new member of our firm with a minimum total trading days of 10 or above you will be assigned the Junior Trader Level. Min. XP Level: 50",
    "view.my_profile.level_live.description": "Successfully trade the live markets and earn the live trader status. Min. XP Level: 300",
    "view.my_profile.level_senior.description": "As a Senior trader you manage other live traders and in return receive a split of their profits. Min. XP Level: 1000",
    "view.my_profile.xp.title": "XP Level",
    "view.my_profile.xp.description": "Gain experience trading the markets with us, going through evaluations and trainings.",
    "view.dashboard.accounts.title": "Accounts",
    "view.dashboard.sidebar.title": "Quick Access",
    "view.dashboard.sidebar.navigation.trading": "Trading",
    "view.dashboard.sidebar.navigation.platform_credentials": "Platform Login",
    "view.dashboard.sidebar.navigation.manage_subscriptions": "Subscriptions",
    "view.dashboard.sidebar.navigation.invite_friends": "Invite Friends",
    "view.dashboard.sidebar.navigation.new_evaluation": "Start new Evaluation",
    "view.dashboard.sidebar.navigation.dashboard": "Dashboard",
    "view.dashboard.sidebar.navigation.checkin": "Checkin",
    "view.dashboard.sidebar.navigation.settings": "Settings",
    "view.dashboard.sidebar.navigation.settings_accounts": "Accounts",
    "view.dashboard.sidebar.navigation.settings_subscriptions": "Subscriptions",
    "view.dashboard.sidebar.navigation.settings_configuration": "Config",
    "view.dashboard.sidebar.navigation.discord": "Discord Chat",
    "view.dashboard.sidebar.navigation.market_data": "Market Data",
    "view.dashboard.balance.title": "Balance",
    "view.dashboard.earn_credits.title": "Earn Reset Credits",
    "view.dashboard.certificates.title": "Certificates",
    "trading_accounts_balance.available_amount": "Available Amount",
    "trading_accounts_balance.modal.title": "No Funds to Withdraw",
    "trading_accounts_balance.modal.content": "You currently do not have a funded account and accordingly have no capital at your disposal. Once you have a funded account, you can withdraw weekly.",
    "trading_accounts_balance.request_payout": "Request Payout",
    // CommunityView
    "view.community.sidebar.feed": "Feed",
    "view.community.sidebar.leaderboard": "Leaderboard",
    "view.community.sidebar.members": "Members", 
    // TradingPlatformView
    "view.trading_platform.title": "Platform Credentials",
    "view.trading_platform.credentials.username": "Username",
    "view.trading_platform.credentials.password": "Password",
    "view.trading_platform.credentials.no_credentials": "It looks like you haven't set up your broker credentials yet. To proceed, please start with an evaluation.",
    "view.trading_platform.login_info": "You must first log into Tradovate with the credentials provided and accept the Market Data Agreement there before market data can be activated on any platform.",
    "view.trading_platform.ninjatrader.title": "NinjaTrader",
    "view.trading_platform.ninjatrader.text": "Connection Guide",
    "view.trading_platform.tradovate.title": "Tradovate",
    "view.trading_platform.tradovate.text": "Open Tradovate.com",
    "view.trading_platform.tradingview.title": "TradingView",
    "view.trading_platform.tradingview.text": "Connection Guide",
    // TradingAccountIndexView
    "view.trading_account.title": "Trading Account",
    "view.trading_account.back_button": "Back to Dashboard",
    "view.trading_account.status.passed": "Passed",
    "view.trading_account.status.failed": "Failed",
    "view.trading_account.status.free_reset": "Free Reset",
    "view.trading_account.status.subscription_canceled": "Subscription Canceled",
    "view.trading_account.status.locked.daily_loss_limit": "Daily Loss Limit",
    "view.trading_account.trading_days_count.title": "Trading Days",
    "view.trading_account.trading_days.title": "Trading Days",
    "view.trading_account.performance_chart.title": "Performance",
    "view.trading_account.performance_chart.balance": "Balance",
    "view.trading_account.performance_chart.target": "Target",
    "view.trading_account.performance_chart.drawdown": "Drawdown",
    "view.trading_account.risk_parameters.title": "Risk Parameters",
    "view.trading_account.risk_parameters.drawdown_level": "Drawdown Level",
    "view.trading_account.risk_parameters.highest_balance": "Highest Balance",
    "view.trading_account.risk_parameters.daily_loss_limit": "Daily Loss Limit",
    "view.trading_account.risk_parameters.weekly_loss_limit": "Weekly Loss Limit",
    "view.trading_account.risk_parameters.max_drawdown": "Max Drawdown",
    "view.trading_account.risk_parameters.none": "None",
    // TradingDayView
    "view.trading_day.title": "Trading Day",
    "view.trading_day.back_button": "Back",
    "view.trading_day.pnl": "PNL",
    "view.trading_day.trades.title": "Trades",
    "view.trading_day.journal.button": "Trading Day Report",
    // TradeView
    "view.trading_trade.title": "Trade",
    "view.trading_trade.back_button": "Back to Trades",
    "view.trading_trade.tags.title": "Tags",
    "view.trading_trade.tags.no_tags": "No Tags",
    "view.trading_trade.tags.new_tag": "New Tag",
    "view.trading_trade.tags.add": "Add",
    "view.trading_trade.tags.delete": "Delete",
    "view.trading_trade.tags.tag_field.placeholder": "Strategy, Idea...",
    "view.trading_trade.notes.title": "Notes",
    "view.trading_trade.update_button": "Update Trade",
    // TradingAccountsList
    "trading_accounts_list.no_accounts": "No Accounts",
    "trading_accounts_list.passed": "Passed",
    "trading_accounts_list.failed": "Failed",
    "trading_accounts_list.free_reset": "Free Reset",
    "trading_accounts_list.subscription_canceled": "Subscription Canceled",
    "trading_accounts_list.subscription_payment_past_due": "Payment Past due",
    "trading_accounts_list.broker_sync": "Broker Synchronization",
    "trading_accounts_list.reset_account": "Reset Account",
    "trading_accounts_list.status.fetching_accounts": "Fetching Accounts",
    "trading_accounts_list.status.syncing_accounts": "Syncing Accounts",
    "trading_accounts_list.status.accounts_up_to_date": "Accounts up to date",
    "trading_accounts_list.status.update_failed": "Update failed, please try again in 10 minutes",
    // TradingDays
    "trading_days.no_trading_days": "No Trading Days",
    "trading_days.api.fetching": "Fetching Trading Days",
    "trading_days.api.syncing": "Syncing with Broker",
    "trading_days.api.completed": "Trading days up to date",
    "trading_days.api.failed": "Update failed, please try again in 10 minutes",
    // SupportChat
    "support_chat.title": "Live Support Chat",
    "support_chat.button": "Support",
    "support_chat.offline.title": "Offline",
    "support_chat.offline.message": "Live support is available Monday-Friday, from 7am-4pm Central Time. During all other times, we will do our best to respond as quickly as possible. You can also reach us by emailing info@purdia.com.",
    "support_chat.not_logged_in": "Sign-Up to access our live support chat.",
    "support_chat.login": "Login",
    "support_chat.sign_up": "Sign-Up",
    // SupportTicket
    "support_ticket.status.open": "Open",
    "support_ticket.status.pending": "In Progress",
    "support_ticket.status.resolved": "Resolved",
    // Checkout
    "view.checkout.feature_list.title": "Features:",
    "view.checkout.sign_up.title": "Sign-Up",
    "view.checkout.address.title": "Billing Address",
    "view.checkout.agreementCheckbox.label": "I have read and understood the <a href=\"https://purdia.com/privacy-policy\" target=\"_blank\">Privacy Policy</a> and I agree to the <a href=\"https://purdia.com/terms-and-conditions\" target=\"_blank\">Terms & Conditions</a>",
    // Sign Up
    "view.login.signUp.label": "I have read and understood the <a href=\"https://purdia.com/privacy-policy\" target=\"_blank\">Privacy Policy</a> and I agree to the <a href=\"https://purdia.com/terms-and-conditions\" target=\"_blank\">Terms & Conditions</a>",
    // Errors
    "errors.account.not_found": "The requested trading account is not available. Please check the account details and try again.",
    "errors.form.field_required": "Field Required",
    "errors.form.field_invalid_email_format": "Invalid email format",
    "errors.form.username_invalid_format": "Username can only contain letters and numbers.",
    "errors.form.username_too_short": "Username must have at least 2 characters.",
    "errors.form.firstname_invalid_format": "First name can only contain letters, hyphens, apostrophes, and spaces. It must start with a letter.",
    "errors.form.lastname_invalid_format": "Last name can only contain letters, hyphens, apostrophes, and spaces. It must start with a letter.",
    "errors.form.login_too_many_requests": "Too many login attempts. Please try again in 10 minutes.",
    "errors.form.email_or_password_invalid": "Incorrect E-Mail or Password",
    "errors.form.form_list_errors": "Please check the form.",
    "errors.form.coupon_invalid": "This code is not valid",
    "errors.form.email_already_exists": "This E-Mail is already registered.",
    "errors.form.friend_already_invited": "This E-Mail is already invited.",
    "errors.form.username_already_exists": "Username already exists",
    "errors.form.password_dont_match": "Passwords do not match.",
    "errors.form.password_min_8_chars": "Password must have at least 8 characters.",
    "errors.form.sign_uniform_subscriber_agreement": "You need to sign the Uniform Subscriber Agreement first",
    "errors.form.min_2_chars": "Field must have at least 2 characters.",
    "errors.form.postalCode_invalid_format": "Postal code invalid",
    "errors.form.only_new_customers": "Only new customers can use this coupon."
  },
  de: {
    "navigation.dashboard": "Übersicht",
    "navigation.trading": "Trading",
    "navigation.education": "Lern Platform",
    "navigation.members": "Mitglieder",
    "navigation.calendar": "Lalendar",
    "navigation.my_account": "Mein Account",
    "navigation.my_account.profile": "Profil",
    "navigation.my_account.bonus": "Bonus",
    "navigation.my_account.settings": "Einstellungen",
    "navigation.my_account.sign_out": "Abmelden",
    "view.settings.title": "Einstellungen",
    "view.settings.sidebar.account": "Account",
    "view.settings.sidebar.subscriptions": "Abonnements",
    "view.settings.sidebar.configuration": "Konfiguration",
    "view.settings.sidebar.affiliate": "Affiliate",
    "view.settings.language": "Sprache",
    "view.settings.edit_profile": "Profil Bearbeiten",
    "view.settings.subscriptions": "Abonnements Verwalten",
  },
  es: {
    "navigation.dashboard": "Resumen",
    "navigation.trading": "Comercio",
    "navigation.education": "Educación",
    "navigation.members": "Miembros",
    "navigation.calendar": "Calendario",
    "navigation.my_account": "Mi Cuenta",
    "navigation.my_account.profile": "Perfil",
    "navigation.my_account.bonus": "Bono",
    "navigation.my_account.settings": "Configuraciones",
    "navigation.my_account.sign_out": "Cerrar Sesión",
    "view.settings.title": "Configuraciones",
    "view.settings.sidebar.account": "Cuenta",
    "view.settings.sidebar.subscriptions": "Suscripciones",
    "view.settings.language": "Idioma",
    "view.settings.edit_profile": "Editar Perfil",
  },
  fr: {
    "navigation.dashboard": "Aperçu",
    "navigation.trading": "Trading",
    "navigation.education": "Éducation",
    "navigation.members": "Membres",
    "navigation.calendar": "Calendrier",
    "navigation.my_account": "Mon Compte",
    "navigation.my_account.profile": "Profil",
    "navigation.my_account.bonus": "Bonus",
    "navigation.my_account.settings": "Paramètres",
    "navigation.my_account.sign_out": "Se Déconnecter",
    "view.settings.title": "Paramètres",
    "view.settings.sidebar.account": "Compte",
    "view.settings.sidebar.subscriptions": "Abonnements",
    "view.settings.language": "Langue",
    "view.settings.edit_profile": "Modifier le Profil",
  },
  ru: {
    "navigation.dashboard": "Обзор",
    "navigation.trading": "Трейдинг",
    "navigation.education": "Обучение",
    "navigation.members": "Члены",
    "navigation.calendar": "Календарь",
    "navigation.my_account": "Мой Аккаунт",
    "navigation.my_account.profile": "Профиль",
    "navigation.my_account.bonus": "Бонус",
    "navigation.my_account.settings": "Настройки",
    "navigation.my_account.sign_out": "Выйти",
    "view.settings.title": "Настройки",
    "view.settings.sidebar.account": "Аккаунт",
    "view.settings.sidebar.subscriptions": "Подписки",
    "view.settings.language": "Язык",
    "view.settings.edit_profile": "Редактировать Профиль",
  },
  zh: {
      "navigation.dashboard": "仪表板",
      "navigation.trading": "交易",
      "navigation.education": "教育",
      "navigation.members": "会员",
      "navigation.calendar": "日历",
      "navigation.partner": "合作伙伴",
      "navigation.evaluations": "评估",
      "navigation.faq": "常见问题",
      "navigation.my_account": "我的账户",
      "navigation.my_account.profile": "个人资料",
      "navigation.my_account.bonus": "奖金",
      "navigation.my_account.settings": "设置",
      "navigation.my_account.sign_out": "登出",
      "view.settings.title": "设置",
      "view.settings.sidebar.account": "账户",
      "view.settings.sidebar.subscriptions": "订阅",
      "view.settings.sidebar.configuration": "配置",
      "view.settings.sidebar.affiliate": "会员",
      "view.settings.language": "语言",
      "view.settings.edit_profile": "编辑个人资料",
      "view.settings.subscriptions": "管理订阅",
      "view.settings.profile.change_profile_picture": "更改头像",
      "view.settings.profile.name": "姓名",
      "view.settings.profile.username": "用户名",
      "view.settings.profile.email": "电子邮件",
      "view.settings.profile.address": "地址",
      "view.settings.profile.update_info": "只能由管理员更新",
      "view.settings.subscriptions.per_month": "每月",
      "view.settings.subscription.title": "订阅",
      "view.settings.subscription.price": "价格",
      "view.settings.subscription.cancel_subscription": "取消订阅",
      "view.settings.subscription.monthly": "每月",
      "view.settings.subscription.status.canceled": "已取消",
      "view.settings.subscription.cancelation_modal.title": "你确定吗？",
      "view.settings.subscription.cancelation_modal.quit": "否",
      "view.settings.subscription.cancelation_modal.confirm": "是的，取消订阅",
      "view.my_profile.title": "我的资料",
      "view.my_profile.level_at_desk": "桌上等级",
      "view.my_profile.at_firm_since": "加入公司以来",
      "view.my_profile.level_junior.description": "作为我们公司的新成员，交易天数至少10天以上，您将被分配初级交易员等级。最低经验值等级：50",
      "view.my_profile.level_live.description": "成功交易实盘并获得实盘交易员状态。最低经验值等级：300",
      "view.my_profile.level_senior.description": "作为高级交易员，您管理其他实盘交易员，并因此获得他们利润的分成。最低经验值等级：1000",
      "view.my_profile.xp.title": "经验值等级",
      "view.my_profile.xp.description": "通过我们的市场交易、评估和培训，获得经验。",
      "view.dashboard.accounts.title": "账户",
      "view.dashboard.accounts.cta": "开始新的评估",
      "view.dashboard.sidebar.title": "快速访问",
      "view.dashboard.sidebar.navigation.platform_credentials": "显示平台凭证",
      "view.dashboard.sidebar.navigation.manage_subscriptions": "管理订阅",
      "view.dashboard.balance.title": "余额",
      "trading_accounts_balance.available_amount": "可用金额",
      "trading_accounts_balance.modal.title": "没有可提取的资金",
      "trading_accounts_balance.modal.content": "您目前没有资金账户，因此没有可支配的资本。一旦您有了资金账户，您可以每周提取最多25,000美元。",
      "trading_accounts_balance.request_payout": "申请支付",
      // TradingPlatformView
      "view.trading_platform.title": "平台凭证",
      "view.trading_platform.credentials.username": "用户名",
      "view.trading_platform.credentials.password": "密码",
      "view.trading_platform.login_info": "您必须首先使用提供的凭证登录Tradovate并在那里接受市场数据协议，然后才能在任何平台上激活市场数据。",
      "view.trading_platform.ninjatrader.title": "NinjaTrader",
      "view.trading_platform.ninjatrader.text": "连接指南",
      "view.trading_platform.tradovate.title": "Tradovate",
      "view.trading_platform.tradovate.text": "打开 Tradovate.com",
      "view.trading_platform.tradingview.title": "TradingView",
      "view.trading_platform.tradingview.text": "连接指南",
      // TradingAccountIndexView
      "view.trading_account.title": "交易账户",
      "view.trading_account.back_button": "返回仪表板",
      "view.trading_account.status.passed": "通过",
      "view.trading_account.status.failed": "失败",
      "view.trading_account.status.free_reset": "免费重置",
      "view.trading_account.status.subscription_canceled": "订阅已取消",
      "view.trading_account.trading_days_count.title": "交易日数",
      "view.trading_account.trading_days.title": "交易日",
      "view.trading_account.performance_chart.title": "绩效图表",
      "view.trading_account.performance_chart.balance": "余额",
      "view.trading_account.performance_chart.target": "目标",
      "view.trading_account.performance_chart.drawdown": "回撤",
      "view.trading_account.risk_parameters.title": "风险参数",
      "view.trading_account.risk_parameters.drawdown_level": "回撤水平",
      "view.trading_account.risk_parameters.highest_balance": "最高余额",
      "view.trading_account.risk_parameters.daily_loss_limit": "每日亏损限额",
      "view.trading_account.risk_parameters.weekly_loss_limit": "每周亏损限额",
      "view.trading_account.risk_parameters.max_drawdown": "最大回撤",
      "view.trading_account.risk_parameters.none": "无",
      // TradingDayView
      "view.trading_day.title": "交易日",
      "view.trading_day.back_button": "返回",
      "view.trading_day.pnl": "盈亏",
      "view.trading_day.trades.title": "盈亏",
      "view.trading_day.journal.button": "交易日报告",
      // TradeView
      "view.trading_trade.title": "交易",
      "view.trading_trade.back_button": "返回交易",
      "view.trading_trade.tags.title": "标签",
      "view.trading_trade.tags.no_tags": "无标签",
      "view.trading_trade.tags.new_tag": "新标签",
      "view.trading_trade.tags.add": "添加",
      "view.trading_trade.tags.delete": "删除",
      "view.trading_trade.tags.tag_field.placeholder": "策略，想法...",
      "view.trading_trade.notes.title": "笔记",
      "view.trading_trade.update_button": "更新交易",
      // TradingAccountsList
      "trading_accounts_list.no_accounts": "无账户",
      "trading_accounts_list.passed": "通过",
      "trading_accounts_list.failed": "失败",
      "trading_accounts_list.broker_sync": "经纪人同步",
      "trading_accounts_list.reset_account": "重置账户",
      "trading_accounts_list.status.fetching_accounts": "获取账户中",
      "trading_accounts_list.status.syncing_accounts": "同步账户中",
      "trading_accounts_list.status.accounts_up_to_date": "账户已更新",
      "trading_accounts_list.status.update_failed": "更新失败，请10分钟后再试",
      // TradingDays
      "trading_days.no_trading_days": "无交易日",
      "trading_days.api.fetching": "正在获取交易日",
      "trading_days.api.syncing": "正在与经纪人同步",
      "trading_days.api.completed": "交易日已更新",
      "trading_days.api.failed": "更新失败，请10分钟后再试",
      // SupportChat
      "support_chat.title": "支持聊天",
      "support_chat.button": "客户支持",
      "support_chat.offline.title": "离线",
      "support_chat.offline.message": "周一至周五上午7点至下午4点提供实时支持。在其他时间，我们会尽力尽快回复。您也可以通过info@purdia.com与我们联系。",
      "support_chat.not_logged_in": "请登录以访问我们的实时支持聊天。如果您无法登录或更愿意通过电子邮件联系，可以随时通过info@purdia.com与我们联系",
      "support_chat.login": "登录",
      "errors.account.not_found": "请求的交易账户不可用。请检查账户详情并重试。",
      "errors.form.field_required": "必填字段",
      "errors.form.field_invalid_email_format": "电子邮件格式无效",
      "errors.form.login_too_many_requests": "尝试登录次数过多。请10分钟后再试。",
      "errors.form.email_or_password_invalid": "电子邮件或密码错误"
  }
};

const i18n = createI18n({
  legacy: false,
  locale: cookie.get("lang") ? cookie.get("lang") : 'en', // set default locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
