<template>
  <div>
    <div
      class="comment"
      style="font-size: 13px; line-height: 13px"
    >
      <UiAvatar class="user-item-avatar" :imgUrl="comment.author.profileImageUrl" @click="onClickMember" size="40px"></UiAvatar>
      <div>
        <div class="font-medium text-gray-800 bg-gray-100 rounded-lg p-2 px-2">
          <div class="cursor-pointer" @click="onClickMember">
            {{ comment.author.firstname }}
            <span class="text-gray-500">@{{ comment.author.username }}</span>
          </div>
          <div
            style="
              font-size: 15px;
              line-height: 17px;
              margin-top: 2px;
              overflow-wrap: anywhere;
            "
          >
            {{ comment.content }}
          </div>
        </div>
        <div class="font-medium text-xs text-gray-400">
          {{ comment.time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const props = defineProps(["comment"]);

const store = useStore();
const router = useRouter();

const onClickMember = () => {
  if (!store.state.auth.authenticated) {
    store.dispatch("pit/toggleRequireLoginModal", true);
    return;
  }
  router.push(`/app/member/${props.comment.author.username}`);
};
</script>

<style>
  .comment {
    display: flex;
  }
</style>