<template>
	<div class="overview-view container">
    <!-- Quick Access Bar -->
          <div class="mobile mb-8">
            <UiHeadline size="m" class="mb-2">
              {{ $t('view.dashboard.sidebar.title') }}
            </UiHeadline>
            <UiCol gap="2">
              <router-link :to="item.route" v-for="item in quickAccessSidebar">
                <UiGridTile clickable>
                  <UiRow gap="2" align="center">
                    <Icon :icon="item.icon" size="24" v-if="item.icon"></Icon> <div>{{ item.name }}</div>
                    <UiBadge v-if="item.isPro">Pro</UiBadge>
                  </UiRow>
                </UiGridTile>
              </router-link>
            </UiCol>
            </div>
 
	       <!-- Page Title -->
        <!-- <UiHeadline style="margin-bottom: 42px;">Dashboard</UiHeadline> -->
          <!-- Row -->
          <div style="display:flex;gap: 72px;margin-top: 8px;">
            <!-- Col -->
            <div style="width: 100%;">
              <UiAlert class="mb-4" color="warning" v-if="$route.query.reward_free_reset">Select the account you want to apply the free reset too.</UiAlert>

              <!-- Support Ticket -->
              <UiCol gap="1" style="margin-bottom: 52px;" v-if="tickets.length">
                <UiGridTile full v-for="ticket in tickets">
                  <SupportTicket v-bind="ticket"></SupportTicket>
                </UiGridTile>
              </UiCol>

              <!-- Balance -->
              <div class="mb-16" v-if="$store.state.dashboard.tradingBalance.balance">
                <UiHeadline size="m" class="mb-2">
                  {{ $t('view.dashboard.balance.title') }}
                </UiHeadline>
                <TradingAccountsBalance></TradingAccountsBalance>
              </div>

              <!-- Trading Accounts -->
              <!-- Headline -->
              <UiHeadline class="mb-2" size="m" bold>{{ $t('view.dashboard.accounts.title') }}</UiHeadline>
              <TradingAccountsList></TradingAccountsList>
              <UiButton class="mt-4" color="primary" href="https://purdia.com/evaluation">Start new Evaluation</UiButton>

              <!-- Earn -->
              <UiHeadline size="m" class="mt-16 mb-2">
                {{ $t('view.dashboard.earn_credits.title') }}
              </UiHeadline>
              <p class="mb-4">Record a video testimonial to share your experience with us or invite a friend to get a free reset on any Evaluation.</p>
              <EarnCredits/>


              <template v-if="store.state.dashboard.version == '0.2.0'">
                <!-- Instant Funding -->
                <UiHeadline size="m" class="mt-16 mb-2">
                  Work with our team and skip the evaluation phase. 
                </UiHeadline>

                <PricingTable></PricingTable>
              </template>

            <!-- Modules -->
            <template v-if="$store.state.dashboard.version === '0.2.0'">
              <hr>
              <!-- Headline -->
              <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;">Modules</div>

              <div class="modules" style="display:grid;grid-template-columns: 1fr 1fr 1fr;gap: 19px;">
                <router-link :to="`/module/${module.id}`" style="border-radius: 16px;background: #F6F7F7;padding: 10px;cursor: pointer;" v-for="module in modules">
                  <div style="display: flex;align-items: center;gap: 8px;">
                    <div style="width: 36px;height: 36px;border-radius: 10px;background: #000;"></div>
                    <div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ module.module.name }}</div>
                  </div>
                </router-link>
              </div>
            </template>

            <template v-if="false">
              <hr>

              <!-- Headline -->
              <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;margin-bottom: 16px;">Risk Params.</div>

              <!-- Stats -->
              <div style="margin-top: 59px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
                <div v-for="stat in account.riskSettings">
                  <!-- Label -->
                  <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;">
                    {{ stat.title }}
                  </div>

                  <!-- Value -->
                  <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ stat.value }}</div>

                  <!-- Info -->
                  <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="stat.info">
                    {{ stat.info }}
                  </div>
                </div>
              </div>
            </template>

            <!-- Balance -->
            <div class="mt-16" v-if="!$store.state.dashboard.tradingBalance.balance">
              <UiHeadline size="m" class="mb-2">
                {{ $t('view.dashboard.balance.title') }}
              </UiHeadline>
              <TradingAccountsBalance></TradingAccountsBalance>
            </div>

          </div>

          <!-- Quick Access Bar -->
          <div class="desktop" style="flex: none;">
            <UiHeadline size="m" class="mb-2">
              {{ $t('view.dashboard.sidebar.title') }}
            </UiHeadline>
            <UiCol gap="2">
              <router-link :to="item.route" v-for="item in quickAccessSidebar">
                <UiGridTile clickable>
                  <UiRow gap="2" align="center">
                    <Icon :icon="item.icon" size="24" v-if="item.icon"></Icon> <div>{{ item.name }}</div>
                    <UiBadge v-if="item.isPro">Pro</UiBadge>
                  </UiRow>
                </UiGridTile>
              </router-link>
            </UiCol>
            </div>
        </div>
    </div>

    <div v-if="false">
      <UiHeadline size="m" style="margin-bottom: 16px;">Strategies</UiHeadline>
      <UiListItem>Support and Resistance <small>Technical analysis</small></UiListItem>

      <UiHeadline size="m" style="margin-bottom: 16px;">Indicators & Tools</UiHeadline>
      <UiListItem>Footprint Chart</UiListItem>

      <UiHeadline size="m" style="margin-bottom: 16px;">Setups</UiHeadline>
      <UiListItem></UiListItem>

      <UiHeadline size="m" style="margin-bottom: 16px;">Pre-Trade Routine</UiHeadline>
      <UiList>
        <UiListItem>Check Daily Chart if (anything has changed, especially after longer break of trading)</UiListItem>
        <UiListItem>Identify Daily Trend</UiListItem>
        <UiListItem>1h Chart + Identify trend and Key Levels</UiListItem>
        <UiListItem>5min Chart + Identify Key Levels</UiListItem>
        <UiListItem>1min Chart + Look for setup for potential entries</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Trading Session</UiHeadline>
      <UiList>
        <UiListItem>Trading Hours | <a>9:30am - 10:30am</a></UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Products</UiHeadline>
      <UiList>
        <UiListItem>NQ</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Setup 1 (Double Top) Entry Criterea</UiHeadline>
      <UiList>
        <UiListItem>Price clearly rejected twice</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Trade Management</UiHeadline>
      <UiList>
        <UiListItem>Place Stop below resistance level</UiListItem>
        <UiListItem>Place Stop above support level</UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Risk Management</UiHeadline>
      <UiList>
        <UiListItem>Max Position Size | <a>1-2 Micro</a></UiListItem>
        <UiListItem>Risk per trade | <a>1%</a></UiListItem>
        <UiListItem>Monthly loss limit | <a>$1000</a></UiListItem>
        <UiListItem>Daily loss limit | <a>$100</a></UiListItem>
      </UiList>

      <UiHeadline size="m" style="margin-bottom: 16px;">Capital Management</UiHeadline>
      <UiList>
        <UiListItem>Account size | <a>$10000</a></UiListItem>
      </UiList>
    </div>


    <UiModal title="Continue Trading" v-if="$route.query.reset_status === 'success'" @close="onCloseResetSuccessModal">
      <div class="mt-4">Your reset was successful.
        <!--<UiBadge>+ You have earned 5 Credits</UiBadge>-->
      </div>

      <UiButton class="mt-8" :to="`/trading/accounts/${$route.query.account_id}?new=true`">Go to Account</UiButton> 
    </UiModal>
</template>

<script setup>
	import { ref, computed, getCurrentInstance } from "vue"
	import { useStore } from "vuex"
  import { useRouter, useRoute } from "vue-router"
  import { Chart } from "@lib/components"
  import TradingChecklist from "../../components/TradingChecklist"
  import TradingAccountsList from "../../components/TradingAccountsList"
  import TradingAccountsBalance from "../../components/TradingAccountsBalance"
  import MarketDataList from "../../components/MarketDataList"
  import SupportTicket from "@/components/Support/Ticket"
  import PricingTable from "@lib/components/functional/PricingTable"
  import EarnCredits from "@/components/EarnCredits"

  const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const store = useStore()
  const router = useRouter()
  const route = useRoute()

  const modules = computed(() => store.state.dashboard.modules)
  const tickets = computed(() => store.state.dashboard.tickets)

  store.dispatch("dashboard/getOpenTickets").then(() => {}).catch(() => {})

  const onCloseResetSuccessModal = () => {
    router.push(`/trading/accounts/${route.query.account_id}?new=true`)
  }

  const quickAccessSidebar = ref([
    {
      name: t('view.dashboard.sidebar.navigation.platform_credentials'),
      icon: "key",
      route: "/platform"
    },
    {
      name: t('view.dashboard.sidebar.navigation.market_data'),
      icon: "folder",
      route: "/settings/configuration"
    },
    {
      name: t('view.dashboard.sidebar.navigation.manage_subscriptions'),
      icon: "folder",
      route: "/settings/subscriptions"
    },
    {
      name: t('view.dashboard.sidebar.navigation.invite_friends'),
      icon: "user_add",
      route: "/module/65bcc1dd8ee854f8b03d1209"
    },
    // {
    //   name: t('view.dashboard.sidebar.navigation.new_evaluation'),
    //   icon: "folder",
    //   route: "/settings/subscriptions"
    // },
    ...(store.state.dashboard.version == '0.2.0') ? [
      {
        name: "Trading Plan",
        icon: "trading",
        route: "/trading/plan",
        isPro: true
      },
      {
        name: "Journal",
        icon: "",
        route: "/trading/journal",
        isPro: true
      },
      {
        name: "Performance Analytics",
        icon: "",
        route: "/trading/performance",
        isPro: true
      },
      {
        name: "Trading Room",
        icon: "",
        route: "/trading/room",
        isPro: true
      },
      {
        name: "Risk Desk",
        icon: "",
        route: "/trading/risk",
        isPro: true
      },
    ] : []
  ])
</script>

<style scoped>
  .overview-view {
    padding: 0 0 128px;
  }
  .modules a {
    color: var(--color-brand);
  }
</style>