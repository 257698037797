<template>
  <div v-if="user" class="flex">
    <div class="flex">
      <div class="p-2" style="font-size: 15px">
        <div
          class="h-9 w-9 rounded-full relative"
          :style="`background:url(${user.profileImageUrl});background-size:contain;box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.4);`"
        ></div>
      </div>
    </div>
    <div class="w-full py-2">
      <textarea
        v-model="postModel.content"
        class="border block w-full rounded-xl border-gray-200 focus:border-gray-300 focus:ring-transparent transition-colors"
        placeholder="Write a comment..."
        style="resize: none"
        @keydown.enter.prevent
        @keyup.enter="onPost"
      />
      <div class="text-right">
        <div class="text-brand font-medium cursor-pointer" @click="onPost">Post Comment</div>
      </div>
      <div v-if="postPreviewImages" class="mt-2 mb-4">
        <div
          v-for="image in postPreviewImages"
          class="bg-gray-100 rounded-lg flex justify-center items-center flex-none w-32 h-24 md:w-48 md:h-32 px-4 border border-gray-200"
          :style="`background: #f3f4f6 url(${image.url});background-position: center;background-size:cover;`"
        ></div>
      </div>
      <div v-if="false" class="flex justify-between">
        <div>
          <label
            class="mt-2 p-2 hover:bg-gray-100 rounded-lg flex text-sm items-center cursor-pointer space-x-1 text-gray-800"
          >
            <input
              type="file"
              class="flex hidden"
              multiple
              @change="handleImageUpload($event)"
              @click="$event.target.value = ''"
            />
            <svg
              class="h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M3 11.6667C3 10.0712 4.2934 8.77779 5.88889 8.77779H7.23169C8.1976 8.77779 9.0996 8.29505 9.63539 7.49137L10.8091 5.73088C11.3448 4.92719 12.2468 4.44446 13.2128 4.44446H18.7872C19.7532 4.44446 20.6552 4.92719 21.1909 5.73088L22.3646 7.49137C22.9004 8.29506 23.8024 8.77779 24.7683 8.77779H26.1111C27.7066 8.77779 29 10.0712 29 11.6667V24.6667C29 26.2622 27.7066 27.5556 26.1111 27.5556H5.88889C4.2934 27.5556 3 26.2622 3 24.6667V11.6667Z"
                stroke="#111827"
                stroke-width="2.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.3333 17.4445C20.3333 19.8377 18.3932 21.7778 16 21.7778C13.6068 21.7778 11.6667 19.8377 11.6667 17.4445C11.6667 15.0512 13.6068 13.1111 16 13.1111C18.3932 13.1111 20.3333 15.0512 20.3333 17.4445Z"
                stroke="#111827"
                stroke-width="2.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="ml-1">Image</div>
          </label>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="p-4 bg-gray-100 rounded-lg text-sm font-medium text-gray-500"
  >
    You need to
    <span
      class="text-brand cursor-pointer"
      @click="store.dispatch('pit/toggleRequireLoginModal', true)"
      >login</span
    >
    to comment
  </div>
</template>

<script setup>
import { ref, defineEmits, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps(["postId"]);
const emit = defineEmits(["submit"]);
const postModel = ref({});
const postImages = ref(new FormData());
const postPreviewImages = ref(null);

const user = computed(() => store.state.user.user);

const handleImageUpload = (e) => {
  const newImages = [];
  newImages.push(...e.target.files);
  postPreviewImages.value = Array.from(e.target.files).map((image) => ({
    url: URL.createObjectURL(image),
  }));
  newImages.forEach((file) => {
    postImages.value.append("files", file);
  });
};

const onPost = () => {
  if (!postModel.value.content.trim()) return;
  postModel.value.postId = props.postId;
  store.dispatch("pit/createPost", postModel.value).then((res) => {
    postModel.value.content = "";
    if (postPreviewImages.value) {
      store
        .dispatch("pit/uploadImagesToPost", {
          postId: res.data.post.id,
          files: postImages.value,
        })
        .then((res) => {
          postImages.value = new FormData();
          emit("submit", res.data.post);
        })
        .catch(() => {});
    } else {
      emit("submit", res.data.post);
    }
  });
};
</script>
