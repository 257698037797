<template>
  <div class="ui-dropdown" v-click-outside="onClickOutside">
    <div class="dropdown" @click="show = !show">
      <slot></slot>
    </div>
    <ul v-if="show" :class="alignmentClass">
      <li v-for="item in items" :key="item.route">
        <router-link :to="item.route" @click="show = false">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  items: Array,
  align: {
    type: String,
    default: 'bottom', // Default alignment
    validator: (value) => ['left', 'right', 'top', 'bottom'].includes(value),
  },
});

const show = ref(false);

const onClickOutside = () => {
  show.value = false;
};

const alignmentClass = computed(() => {
  return {
    'align-left': props.align === 'left',
    'align-right': props.align === 'right',
    'align-top': props.align === 'top',
    'align-bottom': props.align === 'bottom',
  };
});
</script>

<style scoped>
.ui-dropdown {
  position: relative;
}

.ui-dropdown .dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px;
  gap: 15px;
}

.ui-dropdown .dropdown:hover {
  border-bottom: var(--nav-item-active_border-bottom);
  color: var(--nav-item-active_color);
  background: var(--nav-item-active_background);
}

.ui-dropdown ul {
  position: absolute;
  display: block;
  z-index: 16;
  background: #ffffff;
  border: 1px solid #f3f4f6;
  font-size: var(--dropdown_font-size);
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.38px;
  padding: 6px 12px;
  border-radius: 12px;
}

.ui-dropdown ul li a {
  padding: 12px;
  display: inline-block;
  width: 100%;
  color: #000;
}

.ui-dropdown ul li a:hover {
  color: var(--color-brand);
}

/* Alignment Classes */
.align-left {
  left: 0;
}

.align-right {
  right: 0;
}

.align-top {
  bottom: 100%;
}

.align-bottom {
  top: 100%;
}
</style>
