<template>
		<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/" text="Back to Dashboard" fixed></UiBack>
	</div>

	<div class="container" style="max-width: 512px;">
		<UiCard :title="$t('view.trading_platform.title')" icon="key">

		<!-- Credentials -->
		<TradingPlatformCredentials/>

		<!-- Info -->
		<UiAlert class="mt-8" color="warning">
	  	<template v-slot:title>Info</template>
	  	<template v-slot:text>{{ $t('view.trading_platform.login_info') }}</template>
	  </UiAlert>

		<!-- Platforms -->
		<div class="list trading-platform-list">
			<div class="list-item" v-for="platform in platforms">
				<div class="trading-platform-list-group">
					<div class="trading-platform-list-icon">
						<img :src="platform.iconUrl"> 
					</div>
					<div>
						{{ platform.name }}
					</div>
				</div>
				<div>
					<a :href="platform.url.link" target="_blank" v-if="platform.url">{{ platform.url.text }}</a>
				</div>
			</div>
		</div>
  </UiCard>
</div>
</template>

<script setup>
	import { ref, getCurrentInstance } from "vue"
	import TradingPlatformCredentials from "@/components/TradingPlatformCredentials"

	const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const platforms = ref([
		{
			name: t('view.trading_platform.ninjatrader.title'),
			iconUrl: "/img/ninjatrader-logo-icon.png",
			url: {
				text: t('view.trading_platform.ninjatrader.text'),
				link: "/page"
			}
		},
		{
			name: t('view.trading_platform.tradovate.title'),
			iconUrl: "/img/tradovate-logo-icon.png",
			url: {
				text: t('view.trading_platform.tradovate.text'),
				link: "https://trader.tradovate.com"
			}
		},
		{
			name: t('view.trading_platform.tradingview.title'),
			iconUrl: "/img/tradingview-logo-icon.png",
			url: {
				text: t('view.trading_platform.tradingview.text'),
				link: "https://www.youtube.com/watch?v=0VO68XHjhbo"
			}
		}
	])
</script>

<style scoped>
	.trading-platform-list {
		font-size: 14px;
	}

	.trading-platform-list .list-item {
		display: flex;
		align-items: center;
		font-size: 16px;
		padding: 14px 8px;
		justify-content: space-between;
	}

	.trading-platform-list-group {
		display: flex;
		gap: 8px;
	}

	.trading-platform-list-icon {
		height: 24px;
		width: 24px;
		border-radius: 5px;
		border: 1px solid #D1D1D1;
		display: flex;
		justify-content: center;
		align-items: center
		;
	}
</style>