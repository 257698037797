<template>
  <div class="post-head">
    <div class="flex space-x-2">
      <div
        v-if="post.type === 'achievement'"
        class="h-full bg-purple-500 w-1 rounded-full"
      ></div>
      <div
        class="post-head-user"
        style="font-size: 15px"
        @click="showMemberPreview=true"
        :to="`/app/member/${post.author.username}`"
      >
        <UiAvatar class="user-item-avatar" :imgUrl="post.author.profileImageUrl"></UiAvatar>
        <div>
          <div class="post-author-name">
            {{ post.author.firstname }}
            <span class="text-gray-500">@{{ post.author.username }}</span>
          </div>
          <div class="post-time">
            {{ post.time }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div>
        <div
          v-if="post.bookmarked"
          class="flex items-center hover:bg-gray-100 rounded-lg cursor-pointer text-sm md:text-base"
        >
          <div>
            <svg
              class="h-5 md:h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M7.875 6.25C7.875 4.45507 9.33007 3 11.125 3H20.875C22.6699 3 24.125 4.45507 24.125 6.25V29L16 24.9375L7.875 29V6.25Z"
                fill="#205eff"
              />
            </svg>
          </div>
        </div>
      </div>
      <PostActionMenu :post="post"></PostActionMenu>
    </div>

    <!-- Member Preview -->
    <!-- <MemberPreviewModal :member="post.author" v-if="showMemberPreview" @close="showMemberPreview = false"></MemberPreviewModal> -->
  </div>
</template>

<script setup>
import { ref } from "vue"
import PostActionMenu from "./PostActionMenu";
import MemberPreviewModal from "./MemberPreviewModal";

const props = defineProps(["post"]);

const showMemberPreview = ref(false)
</script>

<style>
  .post-head-user {
    display: flex;
    gap: 8px;
    color: #000;
  }
  .post-author-name {
    font-size: 17px;
  }
  .post-time {
    font-size: 13px;
    color: #7b7b7b;
  }
  .post-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #f6f7f7;
  }
</style>