<template>
  <LayoutCheckout>
    <div class="p-4" style="max-width: 512px;margin: auto;">
      <div class="mb-4">
        <UiCard v-if="evaluation">
          <UiRow justify="between">
            <div>
              <label v-if="evaluation.accountSize">${{ evaluation.accountSize }} Account</label>
              <UiHeadline size="m">{{ evaluation.name }}</UiHeadline>
            </div>
          </UiRow>
          <UiCol class="mt-4" gap="1" v-if="evaluation.features.length">
            <small>
              <strong>{{ $t('view.checkout.feature_list.title') }}</strong>
            </small>
            <UiRow gap="2" align="center" v-for="feature in evaluation.features">
              <img src="/img/check.svg">
              <div>{{ feature.title }}</div>
            </UiRow>
          </UiCol>
        </UiCard>
      </div>
      <!-- Form -->
      <UiHeadline class="mb-4 text-center" size="m">
        <template v-if="!store.state.auth.authenticated">
          {{ $t('view.checkout.sign_up.title') }}
        </template>
        <template v-else>{{ $t('view.checkout.address.title') }}</template>
      </UiHeadline>
      <Form :schema="formSchema" v-model="model" :errors="errors" :submitting="submitting" :mode="formMode"></Form>
      <!-- Coupon -->
      <Form :schema="couponFormSchema" v-model="couponModel" @enter="applyDiscountCode" :errors="couponErrors" :submitting="couponSubmitting" :mode="couponFormMode" v-show="store.state.auth.authenticated"></Form>
      <a @click="applyDiscountCode" v-if="couponModel && couponModel.discountCode">Apply coupon code</a>
      <!-- Summary -->
      <div v-if="evaluation" style="text-align: right;">
        <div v-if="discount" style="color: #FF2C46;text-decoration: line-through;">{{ evaluation._price }}</div>
        <div>Total</div>
        <div style="font-size: 20px;color: #215dff;">
          <strong>${{ price.toFixed(2) }}</strong>
        </div>
      </div>
      <!-- Error: Payment -->
      <p style="color: #FF2C46;margin-top: 4px;" v-if="errors && errors.general">{{ errors.general }}</p>
      <!-- Submit -->
      <UiButton class="mt-4" @click="submitCheckout" full size="l" :loading="submitting">Continue</UiButton>
      <div class="mt-8" v-if="!store.state.auth.authenticated">
        <div class="text-center">Have an account already? <router-link class="strong" :to="`/login?redirect=/checkout?plan=${route.query.plan}`">Login</router-link>
        </div>
      </div>
    </div>
  </LayoutCheckout>
</template>
<script setup>
import { ref, computed } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import cookie from "@lib/services/cookie";
import LayoutCheckout from "@/components/LayoutCheckout"

const model = ref({
  country: 'US'
})
const errors = ref(null)
const submitting = ref(false)
const evaluations = ref([])
const store = useStore()
const route = useRoute()
const formMode = ref('form')
const couponModel = ref()
const couponErrors = ref(null)
const couponSubmitting = ref(false)
const couponFormMode = ref('form')
const discount = ref(null)

const features = ref([{
    name: "Professional Team"
  },
  {
    name: "Free Coaching Session"
  },
  {
    name: "Trading Journal"
  },
])

const evaluation = computed(() => evaluations.value.find(e => e.stripePriceId === route.query.plan))
const price = computed(() => evaluation.value ? (discount.value ? ((100 - discount.value.value) / 100 * evaluation.value.price) : evaluation.value.price) : 0)

/**
 * Get Checkout
 */
store.dispatch("dashboard/getCheckout")
const checkout = computed(() => store.state.dashboard.checkoutSession)

const submitCheckout = () => {
  /**
   * Reset
   */
  errors.value = null
  submitting.value = true

  /**
   * Define
   */
  const {
    username,
    email,
    password
  } = model.value
  const pricePlan = route.query.plan
  const cancelUrl = `${process.env.VUE_APP_URL}/checkout?payment=canceled&plan=${pricePlan}`
  const successUrl = process.env.VUE_APP_URL + '?checkout=success'

  /**
   * Tracking
   */
  twq('event', 'tw-od6ms-omrdp', {
    email_address: email,
    phone_number: model.value.phone
  });
  twq('event', 'tw-odisx-odnrv', {
    email_address: email,
    phone_number: model.value.phone
  });

  /**
   * Execute
   */
  store.dispatch("dashboard/checkout", {
    data: {
      address: model.value,
      agreementCheckbox: model.value.agreementCheckbox,
      discount: couponModel.value.discountCode,
      plan: pricePlan,
      rff: cookie.get('rff'),
      tradingPlatform: "tradovate",
      username,
      email,
      password,
      repeatPassword: password,
      steps: 2,
      cancelUrl,
      successUrl,
      checkoutSessionId: checkout.value ? checkout.value.id : null,
      userAgent: navigator.userAgent,
      fbc: cookie.get('_fbc'),
      fbp: cookie.get('_fbp'),
    }
  }).then((res) => {
    /**
     * Update
     */
    submitting.value = false

    // Redirect to Payment Provider
    if (res.data.url) window.location = res.data.url
  }).catch((err) => {
    store.dispatch("dashboard/getCheckout")
    submitting.value = false
    errors.value = err.response.data.errors;
  })
}

const applyDiscountCode = () => {
  /**
   * Reset
   */
  couponErrors.value = null

  /**
   * Validate
   */
  if (!couponModel.value.discountCode) return

  /**
   * Status
   */
  couponSubmitting.value = true

  /**
   * Execute
   */
  store.dispatch("dashboard/checkDiscountCode", {
    data: {
      discountCode: couponModel.value.discountCode,
    }
  }).then((res) => {
    /**
     * Update
     */
    couponSubmitting.value = false
    couponFormMode.value = 'document'
    discount.value = res.data
  }).catch((err) => {
    /**
     * Error
     */
    couponSubmitting.value = false
    couponErrors.value = err.response.data.errors;
  })
}

const fetchEvaluations = () => {
  store.dispatch("dashboard/getEvaluations").then((res) => {
    evaluations.value = res.data.evaluations

  /**
   * Tracking
   */
  twq('event', 'tw-odisx-odnrt', {});
  }).catch(() => {})
}

fetchEvaluations()

if (store.state.user.user) {
  const {
    address
  } = store.state.user.user
  const {
    firstname,
    lastname,
    country,
    street1,
    city,
    state,
    postalCode,
    phone
  } = address

  model.value = {
    firstname,
    lastname,
    country,
    street1,
    city,
    state,
    postalCode,
    phone
  }

  if (firstname && lastname && country && street1 && city && state && postalCode && phone) {
    //formMode.value = 'document'
  }
}

const countries = [
  { label: "United States", code: "US" },
  { label: "Afghanistan", code: "AF", disabled: true },
  { label: "Åland Islands", code: "AX" },
  { label: "Albania", code: "AL", disabled: true },
  { label: "Algeria", code: "DZ" },
  { label: "American Samoa", code: "AS" },
  { label: "AndorrA", code: "AD" },
  { label: "Angola", code: "AO" },
  { label: "Anguilla", code: "AI" },
  { label: "Antarctica", code: "AQ" },
  { label: "Antigua and Barbuda", code: "AG" },
  { label: "Argentina", code: "AR" },
  { label: "Armenia", code: "AM" },
  { label: "Aruba", code: "AW" },
  { label: "Australia", code: "AU" },
  { label: "Austria", code: "AT" },
  { label: "Azerbaijan", code: "AZ" },
  { label: "Bahamas", code: "BS" },
  { label: "Bahrain", code: "BH" },
  { label: "Bangladesh", code: "BD" },
  { label: "Barbados", code: "BB", disabled: true },
  { label: "Belarus", code: "BY", disabled: true },
  { label: "Belgium", code: "BE" },
  { label: "Belize", code: "BZ" },
  { label: "Benin", code: "BJ" },
  { label: "Bermuda", code: "BM" },
  { label: "Bhutan", code: "BT" },
  { label: "Bolivia", code: "BO" },
  { label: "Bosnia and Herzegovina", code: "BA", disabled: true },
  { label: "Botswana", code: "BW" },
  { label: "Bouvet Island", code: "BV" },
  { label: "Brazil", code: "BR" },
  { label: "British Indian Ocean Territory", code: "IO" },
  { label: "Brunei Darussalam", code: "BN" },
  { label: "Bulgaria", code: "BG" },
  { label: "Burkina Faso", code: "BF", disabled: true },
  { label: "Burundi", code: "BI" },
  { label: "Cambodia", code: "KH" },
  { label: "Cameroon", code: "CM", disabled: true },
  { label: "Canada", code: "CA" },
  { label: "Cape Verde", code: "CV" },
  { label: "Cayman Islands", code: "KY", disabled: true },
  { label: "Central African Republic", code: "CF" },
  { label: "Chad", code: "TD" },
  { label: "Chile", code: "CL" },
  { label: "China", code: "CN", disabled: false },
  { label: "Christmas Island", code: "CX" },
  { label: "Cocos (Keeling) Islands", code: "CC" },
  { label: "Colombia", code: "CO" },
  { label: "Comoros", code: "KM" },
  { label: "Congo", code: "CG", disabled: true },
  { label: "Congo, The Democratic Republic of the", code: "CD", disabled: true },
  { label: "Cook Islands", code: "CK" },
  { label: "Costa Rica", code: "CR" },
  { label: "Cote D'Ivoire", code: "CI" },
  { label: "Croatia", code: "HR", disabled: true },
  { label: "Cuba", code: "CU", disabled: true },
  { label: "Cyprus", code: "CY" },
  { label: "Czech Republic", code: "CZ" },
  { label: "Denmark", code: "DK" },
  { label: "Djibouti", code: "DJ" },
  { label: "Dominica", code: "DM" },
  { label: "Dominican Republic", code: "DO" },
  { label: "Ecuador", code: "EC" },
  { label: "Egypt", code: "EG" },
  { label: "El Salvador", code: "SV" },
  { label: "Equatorial Guinea", code: "GQ" },
  { label: "Eritrea", code: "ER" },
  { label: "Estonia", code: "EE" },
  { label: "Ethiopia", code: "ET", disabled: true },
  { label: "Falkland Islands (Malvinas)", code: "FK" },
  { label: "Faroe Islands", code: "FO" },
  { label: "Fiji", code: "FJ" },
  { label: "Finland", code: "FI" },
  { label: "France", code: "FR" },
  { label: "French Guiana", code: "GF" },
  { label: "French Polynesia", code: "PF" },
  { label: "French Southern Territories", code: "TF" },
  { label: "Gabon", code: "GA" },
  { label: "Gambia", code: "GM" },
  { label: "Georgia", code: "GE" },
  { label: "Germany", code: "DE" },
  { label: "Ghana", code: "GH" },
  { label: "Gibraltar", code: "GI", disabled: true },
  { label: "Greece", code: "GR" },
  { label: "Greenland", code: "GL" },
  { label: "Grenada", code: "GD" },
  { label: "Guadeloupe", code: "GP" },
  { label: "Guam", code: "GU" },
  { label: "Guatemala", code: "GT" },
  { label: "Guernsey", code: "GG" },
  { label: "Guinea", code: "GN" },
  { label: "Guinea-Bissau", code: "GW" },
  { label: "Guyana", code: "GY" },
  { label: "Haiti", code: "HT", disabled: true },
  { label: "Heard Island and Mcdonald Islands", code: "HM" },
  { label: "Holy See (Vatican City State)", code: "VA" },
  { label: "Honduras", code: "HN" },
  { label: "Hong Kong", code: "HK" },
  { label: "Hungary", code: "HU" },
  { label: "Iceland", code: "IS" },
  { label: "India", code: "IN" },
  { label: "Indonesia", code: "ID" },
  { label: "Iran, Islamic Republic Of", code: "IR", disabled: true },
  { label: "Iraq", code: "IQ", disabled: true },
  { label: "Ireland", code: "IE" },
  { label: "Isle of Man", code: "IM" },
  { label: "Israel", code: "IL" },
  { label: "Italy", code: "IT" },
  { label: "Jamaica", code: "JM", disabled: true },
  { label: "Japan", code: "JP" },
  { label: "Jersey", code: "JE" },
  { label: "Jordan", code: "JO", disabled: true },
  { label: "Kazakhstan", code: "KZ" },
  { label: "Kenya", code: "KE" },
  { label: "Kiribati", code: "KI" },
  { label: "Korea, Democratic People'S Republic of", code: "KP", disabled: true },
  { label: "Korea, Republic of", code: "KR" },
  { label: "Kuwait", code: "KW" },
  { label: "Kyrgyzstan", code: "KG" },
  { label: "Lao People'S Democratic Republic", code: "LA" },
  { label: "Latvia", code: "LV" },
  { label: "Lebanon", code: "LB", disabled: true },
  { label: "Lesotho", code: "LS" },
  { label: "Liberia", code: "LR" },
  { label: "Libyan Arab Jamahiriya", code: "LY", disabled: true },
  { label: "Liechtenstein", code: "LI" },
  { label: "Lithuania", code: "LT" },
  { label: "Luxembourg", code: "LU" },
  { label: "Macao", code: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { label: "Madagascar", code: "MG" },
  { label: "Malawi", code: "MW" },
  { label: "Malaysia", code: "MY" },
  { label: "Maldives", code: "MV" },
  { label: "Mali", code: "ML", disabled: true },
  { label: "Malta", code: "MT" },
  { label: "Marshall Islands", code: "MH" },
  { label: "Martinique", code: "MQ" },
  { label: "Mauritania", code: "MR" },
  { label: "Mauritius", code: "MU" },
  { label: "Mayotte", code: "YT" },
  { label: "Mexico", code: "MX" },
  { label: "Micronesia, Federated States of", code: "FM" },
  { label: "Moldova, Republic of", code: "MD" },
  { label: "Monaco", code: "MC" },
  { label: "Mongolia", code: "MN" },
  { label: "Montserrat", code: "MS" },
  { label: "Morocco", code: "MA", disabled: true },
  { label: "Mozambique", code: "MZ", disabled: true },
  { label: "Myanmar", code: "MM", disabled: true },
  { label: "Namibia", code: "NA" },
  { label: "Nauru", code: "NR" },
  { label: "Nepal", code: "NP" },
  { label: "Netherlands", code: "NL" },
  { label: "Netherlands Antilles", code: "AN" },
  { label: "New Caledonia", code: "NC" },
  { label: "New Zealand", code: "NZ" },
  { label: "Nicaragua", code: "NI", disabled: true },
  { label: "Niger", code: "NE" },
  { label: "Nigeria", code: "NG", disabled: true },
  { label: "Niue", code: "NU" },
  { label: "Norfolk Island", code: "NF" },
  { label: "Northern Mariana Islands", code: "MP" },
  { label: "Norway", code: "NO" },
  { label: "Oman", code: "OM" },
  { label: "Pakistan", code: "PK", disabled: true },
  { label: "Palau", code: "PW" },
  { label: "Palestinian Territory, Occupied", code: "PS", disabled: true },
  { label: "Panama", code: "PA", disabled: true },
  { label: "Papua New Guinea", code: "PG" },
  { label: "Paraguay", code: "PY" },
  { label: "Peru", code: "PE" },
  { label: "Philippines", code: "PH", disabled: true },
  { label: "Pitcairn", code: "PN" },
  { label: "Poland", code: "PL" },
  { label: "Portugal", code: "PT" },
  { label: "Puerto Rico", code: "PR" },
  { label: "Qatar", code: "QA" },
  { label: "Reunion", code: "RE" },
  { label: "Romania", code: "RO" },
  { label: "Russian Federation", code: "RU", disabled: true },
  { label: "RWANDA", code: "RW" },
  { label: "Saint Helena", code: "SH" },
  { label: "Saint Kitts and Nevis", code: "KN" },
  { label: "Saint Lucia", code: "LC" },
  { label: "Saint Pierre and Miquelon", code: "PM" },
  { label: "Saint Vincent and the Grenadines", code: "VC" },
  { label: "Samoa", code: "WS" },
  { label: "San Marino", code: "SM" },
  { label: "Sao Tome and Principe", code: "ST" },
  { label: "Saudi Arabia", code: "SA" },
  { label: "Senegal", code: "SN", disabled: true },
  { label: "Serbia and Montenegro", code: "CS" },
  { label: "Seychelles", code: "SC" },
  { label: "Sierra Leone", code: "SL" },
  { label: "Singapore", code: "SG" },
  { label: "Slovakia", code: "SK" },
  { label: "Slovenia", code: "SI" },
  { label: "Solomon Islands", code: "SB" },
  { label: "Somalia", code: "SO", disabled: true },
  { label: "South Africa", code: "ZA", disabled: true },
  { label: "South Georgia and the South Sandwich Islands", code: "GS" },
  { label: "Spain", code: "ES" },
  { label: "Sri Lanka", code: "LK" },
  { label: "Sudan", code: "SD", disabled: true },
  { label: "Suriname", code: "SR" },
  { label: "Svalbard and Jan Mayen", code: "SJ" },
  { label: "Swaziland", code: "SZ" },
  { label: "Sweden", code: "SE" },
  { label: "Switzerland", code: "CH" },
  { label: "Syrian Arab Republic", code: "SY", disabled: true },
  { label: "Taiwan, Province of China", code: "TW" },
  { label: "Tajikistan", code: "TJ" },
  { label: "Tanzania, United Republic of", code: "TZ", disabled: true },
  { label: "Thailand", code: "TH" },
  { label: "Timor-Leste", code: "TL" },
  { label: "Togo", code: "TG" },
  { label: "Tokelau", code: "TK" },
  { label: "Tonga", code: "TO" },
  { label: "Trinidad and Tobago", code: "TT" },
  { label: "Tunisia", code: "TN" },
  { label: "Turkey", code: "TR", disabled: true },
  { label: "Turkmenistan", code: "TM", disabled: true },
  { label: "Turks and Caicos Islands", code: "TC" },
  { label: "Tuvalu", code: "TV" },
  { label: "Uganda", code: "UG", disabled: true },
  { label: "Ukraine", code: "UA", disabled: true },
  { label: "United Arab Emirates", code: "AE", disabled: true },
  { label: "United Kingdom", code: "GB" },
  { label: "United States Minor Outlying Islands", code: "UM" },
  { label: "Uruguay", code: "UY" },
  { label: "Uzbekistan", code: "UZ" },
  { label: "Vanuatu", code: "VU" },
  { label: "Venezuela", code: "VE", disabled: true },
  { label: "Viet Nam", code: "VN", disabled: true },
  { label: "Virgin Islands, British", code: "VG" },
  { label: "Virgin Islands, U.S.", code: "VI" },
  { label: "Wallis and Futuna", code: "WF" },
  { label: "Western Sahara", code: "EH" },
  { label: "Yemen", code: "YE", disabled: true },
  { label: "Zambia", code: "ZM" },
  { label: "Zimbabwe", code: "ZW", disabled: true },
];

const usStates = [{
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

const availableCountries = computed(() => {
  return countries.filter((c) => !c.disabled).map(c => ({
    value: c.code,
    label: c.label,
  }));
});


const formSchema = computed(() => `{
  "rows": [
    ${!store.state.auth.authenticated ? `{
      "columns": [
        {
          "fields": [
            {
              "key": "username",
              "type": "text",
              "label": "Username",
              "name": "username",
              "placeholder": ""
            }
          ]
        }
      ]
    },{
      "columns": [
        {
          "fields": [
            {
              "key": "email",
              "type": "text",
              "label": "E-Mail address",
              "name": "email",
              "placeholder": ""
            }
          ]
        }
      ]
    },{
      "columns": [
        {
          "fields": [
            {
              "key": "password",
              "type": "password",
              "label": "Password",
              "name": "password",
              "placeholder": ""
            }
          ]
        }
      ]
    }
    `:`{
      "columns": [
        {
          "fields": [
            {
              "key": "firstname",
              "type": "text",
              "label": "First name",
              "name": "firstname",
              "placeholder": "",
              "required": true
            }
          ]
        },
        {
          "fields": [
            {
              "key": "lastname",
              "type": "text",
              "label": "Last name",
              "name": "lastname",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "country",
              "type": "select",
              "label": "Country",
              "name": "country",
              "placeholder": "",
              "required": true,
              "options": ${JSON.stringify(availableCountries.value)}
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "street1",
              "type": "text",
              "label": "Street address",
              "name": "street1",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "city",
              "type": "text",
              "label": "City",
              "name": "city",
              "placeholder": "",
              "required": true
            }
          ]
        },
        ${model.value.country === 'US' ? `{
          "fields": [
            {
              "key": "state",
              "type": "select",
              "label": "State",
              "name": "state",
              "placeholder": "",
              "options": ${JSON.stringify(usStates)}
            }
          ]
        },`:`{
          "fields": [
            {
              "key": "state",
              "type": "text",
              "label": "State",
              "name": "state",
              "placeholder": ""
            }
          ]
        },`}
        {
          "fields": [
            {
              "key": "postalCode",
              "type": "text",
              "label": "ZIP / Postal code",
              "name": "postalCode",
              "placeholder": ""
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "phone",
              "type": "text",
              "label": "Phone",
              "name": "phone",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    }`},
    {
      "columns": [
        {
          "fields": [
            {
              "key": "agreementCheckbox",
              "type": "checkbox",
              "label": "view.checkout.agreementCheckbox.label",
              "name": "agreementCheckbox",
              "required": true
            }
          ]
        }
      ]
    }
  ]
}
`);

const couponFormSchema = computed(() => `{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "discountCode",
              "type": "text",
              "label": "Coupon Code",
              "name": "discountCode",
              "placeholder": ""
            }
          ]
        }
      ]
    }
  ]
}
`);

</script>
