<template>
	<UiHeadline size="m" style="margin-bottom: 16px;">The top 10 Traders</UiHeadline>

	<UiList style="max-width: 512px;" v-if="leaderboard.members">
		<div v-for="member in leaderboard.members">
			<UiListItem>
				<div style="display: flex;justify-content: space-between;">
					<div>{{ member.firstname }}</div>
					<div style="color: #000;">{{ member.levelName }}</div>
				</div>
			</UiListItem>
		</div>
	</UiList>

	<DataTable :value="leaderboard" tableStyle="min-width: 50rem">
		<Column field="rank" header="#"></Column>
    <Column field="traderName" header="Name"></Column>
    <Column field="profit" header="Profit"></Column>
    <Column field="equity" header="Equity"></Column>
    <Column field="evaluationSize" header="Account Size"></Column>
    <Column field="percentageGain" header="Gain %"></Column>
    <Column field="traderCountry" header="Country"></Column>
</DataTable>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"
	import { LayoutUser } from "@lib/components"
	import DataTable from 'primevue/datatable';
	import Column from 'primevue/column';
	import ColumnGroup from 'primevue/columngroup';   // optional
	import Row from 'primevue/row';                   // optional

	const store = useStore()

	const leaderboard = computed(() => store.state.dashboard.leaderboard)

	store.dispatch("dashboard/getLeaderboard")
</script>