<template>
	<div :class="classes">
		<div class="icon" v-if="icon">
			<Icon :icon="icon" size="24"></Icon>
		</div>
		<slot></slot>
	</div>
</template>


<script setup>
import { computed } from 'vue'
import { Icon } from '../../icons'

const props = defineProps({
	size: {
		type: String,
		default: 'l'
	},
	icon: String,
	bold: Boolean,
	align: {
		type: String,
		default: 'left'
	}
})

const classes = computed(() => [
	'ui-headline',
	`ui-headline-${props.size}`,
	`align-${props.align}`,
	props.bold && 'bold',
])
</script>

<style scoped>
.ui-headline {
	color: #000;
	display: flex;
	gap: 8px;
	align-items: center;
	font-weight: 400; /* Default to regular weight */
}

.ui-headline-l {
	font-size: var(--headline-l-font-size);
	font-weight: 600;
	line-height: 1;
	letter-spacing: -0.52px;
}

.ui-headline-m {
	font-size: var(--headline-m-font-size);
	font-weight: 600;
	line-height: 1;
	letter-spacing: -0.52px;
}

.ui-headline-s {
	font-size: var(--headline-s-font-size);
	font-weight: 600;
	line-height: 1;
}

.darkmode .ui-headline-l {
	color: #62666A;
}

.darkmode .ui-headline-m {
	color: #fff;
}

.darkmode .ui-headline-s {
	color: #969C9D;
}

.ui-headline .icon {
	height: var(--headline-icon-size);
	width: var(--headline-icon-size);
	background: var(--headline-icon-background);
	border: var(--headline-icon-border);
	border-radius: var(--headline-icon-border-radius);
	box-shadow: var(--headline-icon-shadow);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ui-headline.bold {
	font-weight: 600;
}

.ui-headline.align-center {
	text-align: center;
	justify-content: center;
}
</style>
