<template>
  <LayoutUser>
    <div class="password-forgot-view container">
        <div class="form card grid gap-1-5">
          <UiHeadline align="center" size="m">Please wait</UiHeadline>

          <UiLabel size="m">Verification in progress</UiLabel>

          <UiLoadingSpinner></UiLoadingSpinner>
        </div>
    </div>
  </LayoutUser>
</template>

<script setup>
  import { useStore } from "vuex"
  import { useRoute, useRouter } from "vue-router"
  import { LayoutUser } from "@lib/components"

  const store = useStore()
  const route = useRoute()
  const router = useRouter()

  if (route.query.credits && route.query.credits === 'video_testimonial') {
    store
      .dispatch("dashboard/confirmCredit", {
        data: {
          type: 'video_testimonial'
        },
      })
      .then(() => {
        router.push({
          name: 'success',
          query: {
            title: 'Video Testimonial',
            description: 'We have added 1 credit to your account.',
            credits: null
          }
        })
      })
      .catch(() => {
        router.push({
          name: 'error',
          replace: true,
          query: {
            title: 'Video Testimonial',
            description: 'You have already received your credits for you video testimonial.',
            credits: null
          }
        })
      });
  }
</script>

<style scoped>
  .password-forgot-view {
    text-align: center;
    min-height: calc(100vh - var(--user-layout_padding-top));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .password-forgot-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .password-forgot-view .submit {
    margin-top: 24px;
  }
</style>