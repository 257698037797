<template>
	<div :class="classes">
		<div class="modal-bg"></div>
		<div class="modal-inner" v-click-outside="onClickOutside">
			<div class="modal-head">
				<div class="modal-title">
					<UiHeadline size="m">{{ title }}</UiHeadline>
				</div>
				<div class="modal-close" @click="$emit('close')">
					<IconClose color="#AFAFAF80" size="14"></IconClose>
				</div>
			</div>
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
	import { defineEmits, computed } from "vue"
	import { IconClose } from "../../icons"

	const emit = defineEmits(['close'])

	const props = defineProps({
		title: String,
		size: {
			default: 'm',
			type: String
		}
	})

	const onClickOutside = () => {
	    if (!props.preventClose) {
	      emit("close")
	    }
	  }

	  document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape') {
          emit("close")
      }
  });

	const classes = computed(() => [
		'modal',
		`modal-${props.size}`
	])
</script>

<style scoped>
	.modal {
		display: flex;
		top: 0;
		left: 0;
		position: fixed;
		height: 100vh;
		width: 100vw;
		justify-content: center;
		align-items: center;
		z-index: 128;
	}

	.modal-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(244, 244, 244, 0.8);
	}

	.modal-inner {
		max-width: 640px;
    	max-height: 90%;
    	top: 0px;
    	width: 100%;
    	position: relative;
    	bottom: 0;
    	border-radius: 0.75rem;
    	background: #ffffff;
    	padding: 2rem;
    	overflow: auto;
    	color: rgb(0, 0, 0);
	    font-size: 17px;
	    font-weight: 500;
	    line-height: normal;
	    letter-spacing: 0.17px;
	        box-shadow: var(--user-navigation_shadow);
    border: var(--user-navigation_border-bottom);
    -webkit-backdrop-filter: var(--user-navigation_backdrop-filter);
    backdrop-filter: var(--user-navigation_backdrop-filter);
	}
	
	.modal-l .modal-inner {
		max-width: 960px;
	}

	/* width */
	::-webkit-scrollbar {
	  width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: #f1f1f1;
	  border-radius: 999px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #888;
	  border-radius: 999px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #555;
	}

	.modal-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.modal-title {
		font-weight: 500;
	    line-height: 30px;
	    font-size: 20px;
	    color: #101828;
	}

	.modal-close {
		background-color: #f6f7f7;
		width: 32px;
		height: 32px;
		border-radius: 999px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.support-chat-close:hover {
		background: #e5e7eb;
	}
</style>