<template>
	<div class="card" :class="{'has-head':title}">
        <div class="card-head" @click="toggle" v-if="title">
            <div class="card-title">
                <div class="card-icon">
                    <Icon :icon="icon" :size="24"></Icon>
                </div>
                <div>{{ title }}</div>
            </div>
            <div class="collapse" v-if="collapsible"></div>
        </div>
        <div class="card-body" v-if="!collapsed">
		  <slot></slot>
        </div>
	</div>
</template>

<style scoped>
.card {
    background: #fff;
    border: 1px solid #f3f4f6;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 12, 43, .05);
    backdrop-filter: blur(15.5px);
    width: 100%;
    position: relative;
    overflow: auto;
}

.card-elevated {
    box-shadow: 0 4px 8px rgba(0,12,43,.05);
}

.card-head {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px;
}

.card-title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.card-body {
    padding: 20px;
}

.card-icon {
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-footer {
    position: sticky;
    bottom: 0;
    background: #ffffff;
    padding: 12px;
    border-top: 1px solid #e7e7e7;
}

.has-head .card-body {
    padding-top: 1rem;
}

.collapse {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    background: #AFAFAF80;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 14px;
}
</style>

<script setup>
    import { ref } from "vue"
    import UiHeadline from "../Headline/Headline"

    const collapsed = ref(false)

    const props = defineProps({
        title: String,
        collapsible: Boolean,
        expanded: Boolean,
        icon: String
    })

    const toggle = () => {
        if(props.collapsible) collapsed.value = !collapsed.value
    }

    if (!props.expanded && props.collapsible) collapsed.value = true
</script>