<template>
  <CertificatePayout v-bind="certificate"></CertificatePayout>
  <a :href="fileData" download="certificate.jpg">Download</a>
</template>
<script setup>
import CertificatePayout from "@/components/CertificatePayout"
import html2canvas from "html2canvas"
import { ref, computed, onMounted } from "vue"
import { useStore } from "vuex"

const store = useStore()
const fileData = ref(null)

onMounted(() => {
  html2canvas(document.body, {
    backgroundColor: null,
    windowWidth: 540,
    windowHeight: 400,
    width: 540,
    height: 400,
  }).then(canvas => {
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob);
      fileData.value = url;
    }, 'image/jpeg');
  }).catch(error => {
    console.error('Error capturing the element:', error);
  });
});

const certificates = ref([])

store.dispatch("dashboard/getCertificates").then((res) => {})

const certificate = computed(() => store.getters['dashboard/certificate'])

//store.dispatch("dashboard/setCertificate", route.params.certificationId)

</script>
