<template>
  <ul class="project-list">
    <!-- List -->
    <ListItem1 @donate="show = true" v-for="item in items" v-bind="item"></ListItem1>
    <!-- Modal -->
    <Modal title="Donate" v-if="show" @close="show = false">
      <DonationForm></DonationForm>
      <!-- <ProjectList></ProjectList> -->
    </Modal>
  </ul>
</template>
<script setup>
import { ref } from "vue"
import ListItem1 from "@/components/ListItem1"
import Modal from "@/components/Modal"
import DonationForm from "@/components/DonationForm"

const show = ref(false)

const items = ref([{
    title: "April 2024 Group",
    description: "This is a futures beginner course showing you how design a trading plan and choose a startegy that fits to you.",
    price: "Free",
    button: "Start Course",
    tags: ["Beginner", "Free"]
  },
  {
    title: "Futures Beginner",
    description: "This is a futures beginner course showing you how design a trading plan and choose a startegy that fits to you.",
    price: "Free",
    button: "Start Course",
    tags: ["Beginner", "Free"]
  },
])

</script>
<style scoped>
.project-list {
  margin: auto;
  margin-top: 65px;
  list-style-type: none;
  gap: 8px;
  display: grid;
}

</style>
